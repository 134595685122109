import React from 'react'

import ConectaIcon from '../../../../assets/logos/service-logos/conecta-icon-otimizado.png'
import Rectangle1371Png from '../../../../assets/img/Rectangle1371-blue-otimizado.png'
import Img37Png from '../../../../assets/img/image37-otimizado.png'
import Img36Png from '../../../../assets/img/image36-otimizado.png'

import {
  IconTop,
  ContentService,
  InfoContainer,
  TitleInfoContent,
  BlockInfoText,
  ImgContentContainer,
  BackgroundImg,
  Text
} from '../../styles'
import PrimaryTextService from '../../PrimaryTextService'

type Props = {
  children?: React.ReactNode
}

const ConectaService: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <div>
      <IconTop src={ConectaIcon}/>
      <PrimaryTextService>
        <Text color="blue90">Conecta</Text> para buscar as melhores marcas para os seus produtos.
      </PrimaryTextService>
      <ContentService mobileColumnReverse>
        <InfoContainer>
          <TitleInfoContent textColor='blue90'>Marketplace</TitleInfoContent>
          <BlockInfoText>
            Busque as principais marcas em nosso portfólio para gerar novos negócios em licenciamento de marcas.
            <br/>
            <a
              target={'_blank'}
              href="https://conecta.somosdestra.com/marca"
              rel="noreferrer"
            >
              Acesso nosso marketplace
            </a>
          </BlockInfoText>
        </InfoContainer>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img width={'100%'} src={Img36Png} />
          </div>
        </ImgContentContainer>
      </ContentService >
      <ContentService>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img width={'100%'} src={Img37Png} />
          </div>
        </ImgContentContainer>
        <InfoContainer>
          <TitleInfoContent textColor='blue90'>Fale com nosso time</TitleInfoContent>
          <BlockInfoText>Nosso time é especialista em licenciamento de marcas e poderá ajudar sua empresa a escolher a melhor marca para licenciar.</BlockInfoText>
        </InfoContainer>
      </ContentService>
    </div>
  )
}

export default ConectaService
