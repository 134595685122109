import styled from 'styled-components'
import { H6Container } from '../_common/headers/H6/styles'

export const TitleTextContainer = styled.div<{color: string}>`
  /* background-color: red; */

  /* Create the gradient. */
  background-image: ${props => props.theme[props.color]};

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`

export const Text = styled(H6Container)`
  margin: 0px 8px 0 0px;
  text-transform: uppercase;
`
