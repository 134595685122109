import styled from 'styled-components'

export const FAQItemContainer = styled.div`
  width: 100%;
  height: fit-content;
  margin: 10px 0;
`
export const FAQItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
`

export const TitleItem = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
`

export const FAQContent = styled.div<{active: boolean}>`
  display: none;
  height: fit-content;
  width: 95%;
  text-align: left;
  margin: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;

  ${
    props => (
      props.active && `
        display: flex;
        justify-content: left;
      `
    )
  }
`
