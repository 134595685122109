import React from 'react'
import { ToggleBtnContainer, Line, LineX1, LineX2 } from './styles'

type Props = {
  changeCollapsed: () => void;
  active: boolean;
}

const ToggleButton: React.FC<Props> = ({
  changeCollapsed,
  active
}): JSX.Element => {
  return (
    <>
      {
        !active
          ? (
          <ToggleBtnContainer onClick={changeCollapsed}>
            <Line></Line>
            <Line></Line>
            <Line></Line>
          </ToggleBtnContainer>
            )
          : (
          <ToggleBtnContainer onClick={changeCollapsed}>
            <LineX1></LineX1>
            <LineX2></LineX2>
          </ToggleBtnContainer>
            )
    }
  </>

  )
}

export default ToggleButton
