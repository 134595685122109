import styled from 'styled-components'
import { ContentContainer } from '../_common/Content/styles'
import Divisor from '../_common/Divisor'
import { DivisorContainer } from '../_common/Divisor/styles'
import CollapseContent from './CollapseContent'
import { CollapseContentContainer } from './CollapseContent/styles'

export const HeaderContainer = styled.div<{
  theme: 'primary' | 'white'
}>`
  width: 100%;
  height: 80px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  position: fixed;
  z-index: 99999;
  top: 0;
  background: ${props => props.theme.white};


  @media screen and (max-width: 900px) {
    background: none;
    position: absolute;

    ${DivisorContainer} {
      display: none;
    }
  }

`

export const ResponsiveContainer = styled.div`
  width: 100%;
  max-width: 1360px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;

  @media screen and (max-width: 1424px) {
    ${CollapseContentContainer} {
      margin-right: 40px;
    }
  }

  /* @media screen and (max-width: 1200px) {
    justify-content: center;
  } */

  @media screen and (max-width: 1200px) {
    justify-content: space-between;
  }

`

export const ResponsiveLogo = styled.div`

  @media screen and (max-width: 1424px) {
    margin-left: 40px;

  }

  @media screen and (max-width: 900px) {
    display: block;
  }

  @media screen and (max-width: 750px) {
    margin-left: 10px;
  }
`
