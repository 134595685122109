import React from 'react'
import { MainContent } from '../../components/Footer/styles'
import FAQItensRender from './FAQItensRender'
import { FAQContainer, Title } from './styles'

type Props = {
  children?: React.ReactNode
}

const FAQ: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <MainContent flexDirection='column'>
      <Title>
        FAQ
        <div></div>
      </Title>
      <FAQItensRender />
    </MainContent>
  )
}

export default FAQ
