import React from 'react'
import PrimaryText from '../../../components/PrimaryText'
import TitleText from '../../../components/TitleText'
import SocialMediaItens from './SocialMediaItens'
import {
  SocialMediaContainer
} from './styles'

type Props = {
  children?: React.ReactNode
}

const SocialMedia: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <SocialMediaContainer
      flexDirection='column'
    >
      <TitleText color="primary-gradient">Fique por dentro</TitleText>
      <PrimaryText>A Destra nas redes sociais</PrimaryText>
      <SocialMediaItens />
    </SocialMediaContainer>
  )
}

export default SocialMedia
