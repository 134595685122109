import React, { useState } from 'react'
import { Container, ResumeContentContainer } from './styles'
import LeftContent from './LeftContent'
import RightContent from './RigthContent'
import { ContentNameEnum } from '../../../shared/enums'
import { ActiveContentObjType } from '../../../shared/types'
import Content from '../../../components/_common/Content'

type Props = {
  children?: React.ReactNode
  videoRef?: any
}

const ResumeContent: React.FC<Props> = ({
  children,
  videoRef
}): JSX.Element => {
  const [activeContentObj, setActiveContentObj] = useState<ActiveContentObjType>({
    industry: false,
    brand: false,
    // licensing: false,
    aboutDestra: true
  })

  const resetActiveContentObj = {
    industry: false,
    brand: false,
    // licensing: false,
    aboutDestra: false
  }

  const changeActiveContent = (contentName: ContentNameEnum) => setActiveContentObj({
    ...resetActiveContentObj,
    [contentName]: true
  })

  return (
    <ResumeContentContainer flexDirection='row'>

        <Container>
          <LeftContent
            activeContentObj={activeContentObj}
            changeActiveContent={changeActiveContent}
          />
          <RightContent
            activeContentObj={activeContentObj}
            videoRef={videoRef}
          />
        </Container>

    </ResumeContentContainer>
  )
}

export default ResumeContent
