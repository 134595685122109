import React from 'react'
import { faqData } from './faq-data'
import FAQItem from './FAQItem'
import { FAQItensRenderContainer, ItemCntainer } from './styles'

type Props = {
  children?: React.ReactNode
}

const FAQItensRender: React.FC<Props> = ({
  children
}): JSX.Element => {
  const renderItens = () => faqData.map((faq, index) => (
    <ItemCntainer key={index}>
      <FAQItem faq={faq} />
    </ItemCntainer>

  ))
  return (
    <FAQItensRenderContainer>
      {
        renderItens()
      }
    </FAQItensRenderContainer>
  )
}

export default FAQItensRender
