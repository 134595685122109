import { RouteNameEnum } from '../../../shared/enums'
import { RouteAppType } from '../../../shared/types'
import AboutUs from '../../../views/AboutUs'
import FAQ from '../../../views/FAQ'
import Home from '../../../views/Home'
import Cases from '../../../views/Cases'
import Case1 from '../../../views/Cases/Case1'
import Case2 from '../../../views/Cases/Case2'
import Case3 from '../../../views/Cases/Case3'
import Case4 from '../../../views/Cases/Case4'
import ContactUsView from '../../../views/ContactUs'
import IndustrySolutions from '../../../views/our-services/IndustrySolutions'
import BrandSolutions from '../../../views/our-services/BrandSolutions'

export const routes: RouteAppType[] = [
  {
    path: '/',
    view: Home
  },
  {
    headerLabel: 'Quem Somos',
    path: '/sobre',
    view: AboutUs
  },
  {
    headerLabel: 'Nossos Cases',
    path: '/cases',
    view: Cases
  },
  {
    path: `/${RouteNameEnum.CASES}/case1`,
    view: Case1
  },
  {
    path: `/${RouteNameEnum.CASES}/case2`,
    view: Case2
  },
  {
    path: `/${RouteNameEnum.CASES}/case3`,
    view: Case3
  },
  {
    path: `/${RouteNameEnum.CASES}/case4`,
    view: Case4
  },
  {
    headerLabel: 'Nossas Soluções',

    path: `${RouteNameEnum.OUR_SERVICES}`,
    views: [
      {
        headerLabel: 'Para Marcas',
        path: `/${RouteNameEnum.OUR_SERVICES}/marca`,
        view: BrandSolutions
      },
      {
        headerLabel: 'Para Indústria',
        path: `/${RouteNameEnum.OUR_SERVICES}/industria`,
        view: IndustrySolutions
      }
    ]
  },
  {
    headerLabel: 'Blog',
    path: 'https://somosdestra.com/blog/'
  },
  {
    headerLabel: 'Fale conosco',
    path: `/${RouteNameEnum.CONTACT_US}`,
    view: ContactUsView
  },
  {
    headerLabel: 'FAQ',
    path: `/${RouteNameEnum.FAQ}`,
    view: FAQ
  },

  // Redirecionamentos do site antigo
  {
    path: '/performa',
    redirect: `/${RouteNameEnum.OUR_SERVICES}/marca`
  },
  {
    path: '/transforma',
    redirect: `/${RouteNameEnum.OUR_SERVICES}/marca`
  }
]
