import React from 'react'

import { LinkContainer } from './styles'

type Props = {
  to: string | object | VoidFunction,
  children?: React.ReactNode,
  theme?: 'primary' | 'white'
}

const Link: React.FC<Props> = ({
  to,
  children,
  theme = 'primary'
}): JSX.Element => {
  return (
    <LinkContainer color={theme} to={to}>
      {children}
    </LinkContainer>
  )
}

export default Link
