import styled from 'styled-components'

export const ServiceTypeImgContainer = styled.img`
  @media screen and (max-width: 750px) {
    display: none;
  }
`

export const ServiceTypeImgContainerMobile = styled.img`
  display: none;

  @media screen and (max-width: 750px) {
    display: flex
  }
`
