import React from 'react'
import { PrimaryTextServiceContainer } from './styles'

type Props = {
  children?: React.ReactNode
  colorText?: string
  fontSize?: string,
  fontWeight?: number,
  lineHeight?: string,
}

const PrimaryTextService: React.FC<Props> = ({
  children,
  colorText,
  fontSize,
  fontWeight,
  lineHeight
}): JSX.Element => {
  return (
    <PrimaryTextServiceContainer
      colorText={colorText}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
    >
      {children}
    </PrimaryTextServiceContainer>
  )
}

export default PrimaryTextService
