import styled from 'styled-components'

export const FloatingWhatsappContainer = styled.div`
  width: 5rem;
  height: 5rem;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 999999999;

`
