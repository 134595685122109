import Barcelona from '../assets/logos/barcelona-logo-otimizado.png'
import Corinthians from '../assets/logos/Corinthians-logo-otimizado.png'
import CBFLogo from '../assets/logos/CBF-logo-otimizado.png'
import PSGLogo from '../assets/logos/PSG-logo-otimizado.png'
import ZecaLogo from '../assets/logos/Zeca-logo-otimizado.png'
import Marca1 from '../assets/logos/Marca-1-otimizado.png'
import INTZLogo from '../assets/logos/INTZ-logo-otimizado.png'
import FlamengoLogo from '../assets/logos/flamengo-logo-otimizado.png'
import AboveLogo from '../assets/logos/logo-guia-marcas-above-otimizado.png'
import AlpargatasLogo from '../assets/logos/logo-guia-marcas-alpargatas-otimizado.png'
import BlckLogo from '../assets/logos/logo-guia-marcas-blck-otimizado.png'
import BrazilineLogo from '../assets/logos/logo-guia-marcas-braziline-otimizado.png'
import GrendeneLogo from '../assets/logos/logo-guia-marcas-grendene-otimizado.png'
import KonamiLogo from '../assets/logos/logo-guia-marcas-konami-otimizado.png'
import SestiniLogo from '../assets/logos/logo-guia-marcas-sestini-otimizado.png'
import CentauroLogo from '../assets/logos/logo-guia-marcas-centauro-otimizado.png'
import { PartnerArrayType } from './types'

export const BaseOldUrl = 'https://material.somosdestra.com'
export const BrandLicensing = 'licenciamento-de-marca'
export const BaseConecta = 'https://conecta.somosdestra.com'

export const industryPartners: PartnerArrayType[] = [
  { svg: AboveLogo, hideInSmallViewPort: false },
  { svg: AlpargatasLogo, hideInSmallViewPort: false },
  { svg: BlckLogo, hideInSmallViewPort: false },
  { svg: BrazilineLogo, hideInSmallViewPort: false },
  { svg: GrendeneLogo, hideInSmallViewPort: true },
  { svg: KonamiLogo, hideInSmallViewPort: true },
  { svg: CentauroLogo, hideInSmallViewPort: true },
  { svg: SestiniLogo, hideInSmallViewPort: true }
]

export const brandPartners: PartnerArrayType[] = [
  { svg: Barcelona, hideInSmallViewPort: false, path: 'barcelona' },
  { svg: Corinthians, hideInSmallViewPort: false, path: 'corinthians' },
  { svg: PSGLogo, hideInSmallViewPort: false, path: 'psg' },
  { svg: CBFLogo, hideInSmallViewPort: false, path: 'cbf' },
  { svg: ZecaLogo, hideInSmallViewPort: true, path: 'zeca-pagodinho' },
  { svg: Marca1, hideInSmallViewPort: true, path: 'larissa-manoela' },
  { svg: INTZLogo, hideInSmallViewPort: true, path: 'intz' },
  { svg: FlamengoLogo, hideInSmallViewPort: true, path: 'flamengo' }
]
