import React from 'react'
import Divisor from '../../_common/Divisor'
import { Container, DivisorContainer, FooterDivisorContainer } from './styles'

type Props = {
  children?: React.ReactNode
  top?: boolean,
  bottom?: boolean,
}

const FooterDivisor: React.FC<Props> = ({
  children,
  top = false,
  bottom = false
}): JSX.Element => {
  return (
    <FooterDivisorContainer top={top} bottom={bottom}>
      <Container>
        <DivisorContainer />
    </Container>
    </FooterDivisorContainer>
  )
}

export default FooterDivisor
