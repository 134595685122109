import React from 'react'

import { LinkItemContainer } from './styles'
import Link from './Link'

type Props = {
  to: string | object | VoidFunction,
  children?: React.ReactNode,
  hoverEffect?: boolean,
  theme?: 'primary' | 'white'
}

const LinkItem: React.FC<Props> = ({
  to,
  children,
  hoverEffect = true,
  theme = 'primary'
}): JSX.Element => {
  return (
    <LinkItemContainer color={theme}>
      <Link theme={theme} to={to}>
        {children}
      </Link>
      {
        hoverEffect && <span></span>
      }
    </LinkItemContainer>
  )
}

export default LinkItem
