import styled from 'styled-components'

export const ButtonsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;
  width: 250px;

  @media screen and (max-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 50px auto;
    width: 100%;
  }

`
