import { Link } from 'react-router-dom'
import styled from 'styled-components'

type Props = {
  to: string | object | VoidFunction,
  color: 'primary' | 'white'
}

export const LinkContainer = styled(Link)<Props>`
  color: ${(props) => props.color === 'white' ? props.theme[props.color] : props.theme.black};
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  display: block;

  @media screen and (max-width: 1200px) {
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    display: block;
    margin: 20px;
  }

`
