import React from 'react'
import OptionBtn from './OptionBtn'
import { LeftContentContainer } from './styles'
import IndustryIcon from '../../../../assets/img/resume/industry.svg'
import BrandIcon from '../../../../assets/img/resume/brand.svg'
import LogoDestraD from '../../../../assets/logos/logo-destra/logoDestraD-otimizado.png'
import { ContentNameEnum } from '../../../../shared/enums'
import { ActiveContentObjType } from '../../../../shared/types'

type Props = {
  children?: React.ReactNode,
  activeContentObj: ActiveContentObjType
  changeActiveContent: (contentName: ContentNameEnum) => void,
}

const LeftContent: React.FC<Props> = ({
  activeContentObj,
  changeActiveContent
}): JSX.Element => {
  return (
    <LeftContentContainer>
      <OptionBtn
        contentName={ContentNameEnum.ABOUT_DESTRA}
        backgroundColor="primary-gradient"
        activeContentObj={activeContentObj}
        icon={LogoDestraD}
        onClick={() => changeActiveContent(ContentNameEnum.ABOUT_DESTRA)}
      >
        Sobre a Destra
      </OptionBtn>
      <OptionBtn
        contentName={ContentNameEnum.INDUSTRY}
        activeContentObj={activeContentObj}
        backgroundColor="blue-gradient"
        icon={IndustryIcon}
        onClick={() => changeActiveContent(ContentNameEnum.INDUSTRY)}
      >
        Sou Indústria
      </OptionBtn>
      <OptionBtn
        contentName={ContentNameEnum.BRAND}
        backgroundColor="orange-gradient"
        activeContentObj={activeContentObj}
        icon={BrandIcon}
        onClick={() => changeActiveContent(ContentNameEnum.BRAND)}
      >
        Sou Marca
      </OptionBtn>
    </LeftContentContainer>
  )
}

export default LeftContent
