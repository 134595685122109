import styled from 'styled-components'

export const WhatsappPhoneContainer = styled.div`
  cursor: pointer;
`
export const PhoneNumber = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: start;
  line-height: 21px;
  margin-top: 2px;

  img {
    margin-right: 5px;
  }

  @media screen and (max-width: 650px) {
    font-size: 16px
  }
`
