import React from 'react'
import OptionBtn from './OptionBtn'
import { ButtonsContentContainer } from './styles'
import IndustryIcon from '../../../../assets/img/resume/industry.svg'
import BrandIcon from '../../../../assets/img/resume/brand.svg'
import LicensingPortalIcon from '../../../../assets/img/resume/licensing-portal.svg'
import { ContentNameEnum, RouteNameEnum } from '../../../../shared/enums'
import { ActiveContentObjType } from '../../../../shared/types'
import { useNavigate } from 'react-router-dom'

type Props = {
  children?: React.ReactNode,
  activeContentObj: ActiveContentObjType
  changeActiveContent: (contentName: ContentNameEnum) => void,
}

const ButtonsContent: React.FC<Props> = ({
  activeContentObj,
  changeActiveContent
}): JSX.Element => {
  const navigate = useNavigate()

  return (
    <ButtonsContentContainer>
      <OptionBtn
        contentName={ContentNameEnum.INDUSTRY}
        activeContentObj={activeContentObj}
        backgroundColor="primary-gradient"
        icon={IndustryIcon}
        onClick={() => navigate(`/${RouteNameEnum.OUR_SERVICES}/industria`)}
      >
        Sou Indústria
      </OptionBtn>
      <OptionBtn
        contentName={ContentNameEnum.BRAND}
        backgroundColor="orange-gradient"
        activeContentObj={activeContentObj}
        icon={BrandIcon}
        onClick={() => navigate(`/${RouteNameEnum.OUR_SERVICES}/marca`)}
      >
        Sou Marca
      </OptionBtn>
    </ButtonsContentContainer>
  )
}

export default ButtonsContent
