import React from 'react'
import { MainContent } from '../../components/Footer/styles'
import VideoContent from '../../components/VideoContent'
import ContactUs from '../../components/ContactUs'
import AboutUsForm from '../../components/AboutUs'
import {
  BlockText,
  ContentServices,
  HeaderContent,
  LinkServices,
  Logos,
  TextContainer
} from './styles'
import HeaderImg from '../../components/HeaderImg'
import PrimaryText from '../../components/PrimaryText'
import LogoDestraOnlyDJPG from '../../assets/logos/logo-destra/logoDestraD-otimizado.png'
import GroupPerformaPNG from '../../assets/img/Group-Performa-otimizado.png'
import GroupTransformaPNG from '../../assets/img/group-transforma-otimizado.png'
import GroupConectaPNG from '../../assets/img/group-conecta-otimizado.png'
import { Text } from '../Home/DiscoverOurServices/InfoContent/styles'
import Content from '../../components/_common/Content'
import HeaderAboutUsJPG from '../../assets/img/about-us-header.jpg'
import { useNavigate } from 'react-router-dom'
import { RouteNameEnum, ServiceTypeEnum } from '../../shared/enums'

type Props = {
  children?: React.ReactNode
}

const AboutUs: React.FC<Props> = ({
  children
}): JSX.Element => {
  const navigate = useNavigate()

  return (
    <>
        <HeaderImg src={HeaderAboutUsJPG} height={'300px'} />
        <MainContent flexDirection='column'>
          <HeaderContent>
            <Logos src={LogoDestraOnlyDJPG} />
            <PrimaryText colorText='white'>
              Seu <Text color="warning">braço direito</Text> em licenciamento de marcas
            </PrimaryText>
          </HeaderContent>
          <TextContainer>
            <br/>
            <PrimaryText colorText='primary'>
              Sobre a Destra
            </PrimaryText>
            <br/>
            <BlockText>
            Não somos uma agência de licenciamento, somos uma plataforma de LaaS, Licensing as a Service (Licenciamento como Serviço), que atua em 3 frentes: Transformação, Conexão e Performance.
            </BlockText>
            <BlockText>
            <b>Transformação</b>: Com cursos, aulas e um extenso material sobre licenciamento de marcas, buscamos auxiliar o desenvolvimento do mercado.
            </BlockText>
            <BlockText>
            <b>Conexão</b>: Através do Destra Conecta, nosso marketplace, geramos novas oportunidades de negócios para empresas licenciadas e marcas.
            </BlockText>
            <BlockText>
            <b>Performance</b>: Através do Destra Performa gerenciamos contratos de licenciamento, reduzindo as atividades operacionais e permitindo mais avanços estratégicos nos departamentos de licenciamento.
            </BlockText>
            <BlockText>
            <b>Juntamos o melhor da tecnologia com o melhor de uma agência de licenciamento de marcas em uma única solução.</b>
            </BlockText>

            {
              // rever posicionamento dos BlockText - espaçamento &nbsp; solução paliativa
            }
            <BlockText>
            Somos especialistas em gerar e gerir negócios em licenciamento de marcas. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </BlockText>

          </TextContainer>
        </MainContent>
        <VideoContent />
        <MainContent margin="150px auto" flexDirection='column'>
          <Content flexDirection='column'>
            <PrimaryText>
              Conheça nossas soluções
            </PrimaryText>
          </Content>

            <Content flexDirection='column' margin='50px 0'>
              <PrimaryText colorText='blue90'>
                Para Indústria
              </PrimaryText>
            </Content>
            <ContentServices justifyContent='space-between'>
              <LinkServices onClick={() => navigate(`/${RouteNameEnum.OUR_SERVICES}/industria`, { state: ServiceTypeEnum.TRANSFORMA })}>
                <Logos src={GroupTransformaPNG} />
              </LinkServices>
              <LinkServices onClick={() => navigate(`/${RouteNameEnum.OUR_SERVICES}/industria`, { state: ServiceTypeEnum.CONECTA })}>
                <Logos src={GroupConectaPNG} />
              </LinkServices>
              <LinkServices onClick={() => navigate(`/${RouteNameEnum.OUR_SERVICES}/industria`, { state: ServiceTypeEnum.PERFORMA })}>
                <Logos src={GroupPerformaPNG} />
              </LinkServices>
            </ContentServices>

            <Content flexDirection='column' margin='50px 0'>
              <PrimaryText colorText='warning'>
                Para Marcas
              </PrimaryText>
            </Content>
            <ContentServices justifyContent='space-between'>
              <LinkServices onClick={() => navigate(`/${RouteNameEnum.OUR_SERVICES}/marca`, { state: ServiceTypeEnum.TRANSFORMA })} >
                <Logos src={GroupTransformaPNG} />
              </LinkServices>
              <LinkServices onClick={() => navigate(`/${RouteNameEnum.OUR_SERVICES}/marca`, { state: ServiceTypeEnum.CONECTA })} >
                <Logos src={GroupConectaPNG} />
              </LinkServices>
              <LinkServices onClick={() => navigate(`/${RouteNameEnum.OUR_SERVICES}/marca`, { state: ServiceTypeEnum.PERFORMA })} >
                <Logos src={GroupPerformaPNG} />
              </LinkServices>
            </ContentServices>

        </MainContent>
        <AboutUsForm />
    </>
  )
}

export default AboutUs
