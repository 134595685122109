import styled from 'styled-components'

export const SocialMediaItensContainer = styled.div`
  margin: 40px 0;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`
export const SocialMediaItem = styled.div`
  cursor: pointer;
  width: 242px;
  height: 316px;
  border-radius: 4px;
  margin: 10px;
  background: ${props => props.theme['primary-gradient']};
  box-shadow: 5px 5px 5px ${props => props.theme.gray40};

  @media screen and (max-width: 1100px) {

    div:nth-child(2) img {
      width: 15%;
    }
  }

`

export const SocialMediaIlustration = styled.img`
  width: 242px;
  height: 216px;
  border-radius: 4px 4px 0px 0px;
`

export const SocialMediaLinkContainer = styled.div`
  text-decoration: none;
  color: ${props => props.theme.black};
  width: 100%;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  background-color: ${props => props.theme.white};

`

export const IconSvg = styled.img<{
  heightIcon?: number,
  widthIcon?: number
}>`
  padding: 0;
  margin: 5px 0;

  height: ${props => props.heightIcon || '25%'};
`
export const TextContainer = styled.div`
  width: 242px;
  height: 216px;
  border-radius: 4px 4px 0px 0px;
  color: ${props => props.theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const CarouselSocialMediaContainer1100px = styled.div`
  display: none;

  @media screen and (max-width: 1100px) {
    .slide {
      display: flex;
      align-items: center;
    }

    .slider-wrapper {
      width: 500px;
      height: 370px;
    }

    display: block;
    width: 100%;
    height: max-content;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`
export const CarouselSocialMediaContainer600px = styled.div`
  display: none;

  @media screen and (max-width: 1100px) {
    .slide {
      display: flex;
      align-items: center;
    }

    .slider-wrapper {
      width: 500px;
      height: 370px;
    }

    /* display: block; */
    width: 100%;
    height: max-content;
  }

  @media screen and (max-width: 600px) {
    .slide {
      display: flex;
      align-items: center;
    }

    .slider-wrapper {
      width: 90%;
      height: 370px;
    }

    display: block;
    /* width: 100%; */
    height: max-content;
  }
`
