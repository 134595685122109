import React from 'react'
import { useNavigate } from 'react-router-dom'
import PrimaryText from '../../../../components/PrimaryText'
import TitleText from '../../../../components/TitleText'
import VectorRigth from '../../../../components/_common/VectorRigth'

import {
  SecundaryText,
  ResumeContainer,
  ButtonPrimary
} from './styles'

const BrandResume: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  return (
    <ResumeContainer>
      <TitleText color="orange-gradient">Sou Marca</TitleText>
      <PrimaryText>Gere mais royalties!</PrimaryText>
      <SecundaryText>
        Aumente seus royalties com novos negócios em licenciamento e
        <br/>realize a gestão dos seus contratos
      </SecundaryText>
      <ButtonPrimary
        background='orange-gradient'
        onClick={() => navigate('/nossos-servicos/marca')}
      >
        Gerencie seus contratos
      </ButtonPrimary>

    </ResumeContainer>
  )
}

export default BrandResume
