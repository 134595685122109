import styled from 'styled-components'
import { ButtonContainer } from '../../../components/Button/styles'
import { ContentContainer } from '../../../components/_common/Content/styles'
import { H6Container } from '../../../components/_common/headers/H6/styles'

export const PartnersContainer = styled.div`
  max-width: 1120px;
  margin: 60px auto;

  @media screen and (max-width: 1200px) {
    max-width: 700px
  }
`

export const PartnersContent = styled(ContentContainer)`
  flex-direction: column;
`

export const SecundaryText = styled(H6Container)`
  margin: 16px 0px 20px 0px;
  color: ${props => props.theme.gray70}
`

export const Button = styled(ButtonContainer)`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;

  margin: 32px 8px;
  width: 256px;
  height: 52px;

  @media screen and (max-width: 600px) {
    margin: 10px 0;
    width: 100%;
  }
`
export const VectorText = styled(H6Container)`
  margin: 10px 0px;
  color: ${props => props.theme.black}
`

export const ButtonPrimary = styled(Button)`
  background: ${props => props.theme['primary-gradient']};
  color: ${props => props.theme.white};
`
