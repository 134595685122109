import styled from 'styled-components'

export const TransformaServiceContainer = styled.div`

`
export const ContentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(39, 188, 134, 0.08);
  border-radius: 20px;
  padding: 70px;
  margin: 30px auto;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    padding: 20px 0;

  }
`
export const IconContainer = styled.img<{ onClick?: () => void }>`
  width: 184px;
  height: 206px;
  cursor: ${props => props.onClick && 'pointer'};

  @media screen and (max-width: 1024px) {
    margin-bottom: 20px;
  }
`

export const InfoContainer = styled.div`
  width: 70%;
  text-align: left;
`
