import React from 'react'
import { BannerTypeEnum } from '../../../../shared/enums'
import { BannerConfigType, SocialMediaItemConfig } from '../../../../shared/types'
import { BannerIframe, BannerIframeContainer, ImgBanner, SocialMediaBannerContainer } from './styles'

type Props = {
  children?: React.ReactNode
  banner: BannerConfigType,
}

const SocialMediaBanner: React.FC<Props> = ({
  banner
}): JSX.Element => {
  const renderBannerImage = ({ src }: BannerConfigType) => (
    <SocialMediaBannerContainer>
      <ImgBanner src={src} />
    </SocialMediaBannerContainer>
  )

  const renderBannerIframe = ({ src }: BannerConfigType) => (
    <BannerIframeContainer>
      <BannerIframe src={src} />
    </BannerIframeContainer>
  )

  const renderBanner = () => {
    switch (banner.type) {
      case BannerTypeEnum.IMAGE:
        return renderBannerImage(banner)
      case BannerTypeEnum.IFRAME:
        return renderBannerIframe(banner)
    }
  }

  return (
    <>
      {
        renderBanner()
      }
    </>
  )
}

export default SocialMediaBanner
