import React, { useRef } from 'react'

import VectorDown from '../../components/_common/VectorDown'
import Divisor from '../../components/_common/Divisor'
import ContactUs from '../../components/ContactUs'
import ResumeContent from './ResumeContent'
import DiscoverOurServices from './DiscoverOurServices'
import CustomersTestimonials from './CustomersTestimonials'
import Partners from './Partners'
import SocialMedia from './SocialMedia'
import VideoContent from '../../components/VideoContent'
import MainContent from '../../components/_common/MainContent'

type Props = {
  children?: React.ReactNode
}

const Home: React.FC<Props> = (): JSX.Element => {
  const videoRef = useRef(null)
  return (
    <MainContent>
      <ResumeContent videoRef={videoRef}/>
      <VectorDown videoRef={videoRef} />
      <VideoContent videoRef={videoRef} />
      <Divisor />
      <DiscoverOurServices />
      <Divisor />
      <Partners />
      <Divisor />
      <CustomersTestimonials />
      <Divisor />
      <SocialMedia />
      <Divisor />
      <ContactUs />
    </MainContent>
  )
}

export default Home
