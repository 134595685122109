import styled from 'styled-components'
import { PrimaryTextContainer } from '../../components/PrimaryText/styles'

export const SolutionsContainer = styled.div`

`

export const IconTop = styled.img`
  margin-top: 100px;
`
export const ContentService = styled.div<{
  flexDirection?: string,
  mobileColumnReverse?: boolean
}>`
  margin: 100px auto;
  display: flex;
  flex-direction: ${props => props.flexDirection && props.flexDirection};
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: ${props => {
      if (props.mobileColumnReverse) {
        return 'column-reverse'
      }
      return 'column'
    }};
    align-items: center;

  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  text-align: left;
  height: 100%;
  align-self: center;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }

  @media screen and (max-width:900px) {
    width: 80%;
  }
`

export const ImgContentContainer = styled.div`
  width: 48%;
  position: relative;

  @media screen and (max-width:900px) {
    width: 60%;
  }
`

export const TitleInfoContent = styled.div<{ textColor: string }>`

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;

  color: ${props => props.theme[props.textColor]};
`
export const BlockInfoText = styled.div`
  margin: 16px 0;

  word-wrap: break-word;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
`

export const BackgroundImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
`

export const Text = styled(PrimaryTextContainer)<{color: string}>`
  display: contents;
  color: ${props => props.theme[props.color]}
`

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

export const ContainerSelect = styled.div`
  height: 64px;
  width: 100%;
`

export const HeaderContentServices = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

export const SublimeTitle = styled.div`
  margin-top: 24px;
  position: relative;
  border-radius: 3px;
  width: 5%;
  height: 6px;
  background-color: ${props => props.theme.white};

  @media screen and (max-width: 750px) {
    margin-top: 12px;
  }
`
