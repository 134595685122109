import styled from 'styled-components'

export const SelectServicesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 700px) {
    justify-content: center;
  }
`

export const ServiceTypeTitleImg = styled.img`

`
