export enum ContentNameEnum {
  INDUSTRY = 'industry',
  BRAND = 'brand',
  ABOUT_DESTRA = 'aboutDestra'
}

export enum RouteNameEnum {
  HOME = 'home',
  ABOUT_US = 'sobre',
  FAQ = 'faq',
  CASES = 'cases',
  OUR_SERVICES = 'nossos-servicos',
  BLOG = 'blog',
  CONTACT_US = 'fale-conosco'
}

export enum ServiceTypeEnum {
  CONECTA = 'conecta',
  PERFORMA = 'performa',
  TRANSFORMA = 'transforma'
}

export enum BannerTypeEnum {
  IFRAME = 'iframe',
  IMAGE = 'img'
}
