import React, { useEffect, useState, useRef, memo } from 'react'
import PrimaryText from '../PrimaryText'
import TitleText from '../TitleText'
import FormContactUs from './FormContactUs'
import { ContactUsContainer } from './styles'

type Props = {
  children?: React.ReactNode
}

const ContactUs: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <ContactUsContainer flexDirection='column'>
      <TitleText color="primary-gradient">Contato</TitleText>
      <PrimaryText>Fale conosco</PrimaryText>
      <iframe width="100%" height="750" src="https://conecta.somosdestra.com/forms/form1.php" title="Formulario" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </ContactUsContainer>
  )
}

export default ContactUs
