import styled from 'styled-components'

export const FAQItensRenderContainer = styled.div`
  width: 100%;
  height: fit-content;
  margin-bottom: 50px;
`

export const FAQItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
`

export const TitleItem = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
`

export const ItemCntainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 20px 0;
  border-bottom: 1px solid ${props => props.theme.gray40};
`
