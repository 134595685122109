import styled from 'styled-components'

export const FooterDivisorContainer = styled.div<{top?: boolean, bottom?: boolean}>`
  margin-bottom: ${props => props.bottom && '20px'};
  margin-top: ${props => props.top && '20px'};
`

export const DivisorContainer = styled.div`
  width: 80%;
  border: 1px solid ${props => props.theme.gray30};
  background-color: ${props => props.theme.gray30};

  @media screen and (max-width: 1000px) {
    width: 95%;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1px;
`
