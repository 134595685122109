import React from 'react'
import PrimaryText from '../../../components/PrimaryText'
import TitleText from '../../../components/TitleText'
import VectorRigth from '../../../components/_common/VectorRigth'
import LogosPartnersDisplay from './LogosPartnersDisplay'
import { ButtonPrimary, PartnersContainer, PartnersContent, SecundaryText, VectorText } from './styles'

type Props = {
  children?: React.ReactNode
  partner: 'brands' | 'industries'
}

const Partners: React.FC<Props> = ({
  children,
  partner
}): JSX.Element => {
  const handleLabel = () => partner === 'brands' ? 'marcas' : 'indústrias'

  return (
    <PartnersContainer>
      <PartnersContent flexDirection='column' alignItems='center'>
        <PrimaryText>Conheça algumas {handleLabel()} que já são nossos clientes</PrimaryText>
        <LogosPartnersDisplay partner={partner} />
        <br />
        <br />
        {/* <VectorText>Conheça nossas soluções para {handleLabel()} <VectorRigth /></VectorText> */}
      </PartnersContent>
    </PartnersContainer>
  )
}

export default Partners
