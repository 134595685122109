import styled from 'styled-components'

type Props = {
  color: 'primary' | 'white'
}

export const LinkItemContainer = styled.div<Props>`
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
  position: relative;

  span {
    width: 0px;
    height: 0px;
  }

  @media screen and (max-width: 1200px) {
    margin: 10px auto;
    justify-content: start;
    align-items: center;
  }

  &:hover {
    span {
      width: 20px;
      height: 1px;
      background-color: ${props => props.theme[props.color]};

      position: absolute;
      bottom: 0px;
      transition: all .2s ease-in-out;
      transform: scale(2);
    }
  }
`

export const LoginBtnItem = styled.div`
  margin: 10px;
`
