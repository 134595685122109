import React from 'react'
import { ContentNameEnum } from '../../../../shared/enums'
import { ActiveContentObjType } from '../../../../shared/types'
import AboutResume from './AboutResume'

import BrandResume from './BrandResume'
import IndustryResume from './IndustryResume'
import LicensingResume from './LicensingResume'
import { Iframe, IframeContainer, ResumeContainer, RigthContentContainer } from './styles'

type Props = {
  activeContentObj: ActiveContentObjType
  videoRef?: any
}

const RigthContent: React.FC<Props> = ({
  activeContentObj,
  videoRef
}): JSX.Element => {
  return (
    <RigthContentContainer>
       {
        activeContentObj[ContentNameEnum.ABOUT_DESTRA] && (
          <AboutResume videoRef={videoRef} />
        )
      }
      {
        activeContentObj[ContentNameEnum.INDUSTRY] && (
          <IndustryResume />
        )
      }
      {
        activeContentObj[ContentNameEnum.BRAND] && (
          <BrandResume />
        )
      }
      {/* {
        activeContentObj[ContentNameEnum.LICENSING] && (
          <LicensingResume />
        )
      } */}
    </RigthContentContainer>
  )
}

export default RigthContent
