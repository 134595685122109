import styled from 'styled-components'

export const RigthContentContainer = styled.div<{ justifyContent?: string }>`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: ${props => props.justifyContent || 'center'};
  width: 80%;
  height: auto;
`
