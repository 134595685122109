import styled from 'styled-components'

export const CaseItemContainer = styled.div<{onClick?: any}>`
  width: 540px;
  height: 500px;
  cursor: pointer;

  @media screen and (max-width: 1300px) {
    margin: 0 10px;
    height: 400px;
  }

  @media screen and (max-width: 1000px) {
    margin: 0 10px;
    height: 400px;
  }

  @media screen and (max-width: 750px) {
    margin: 20px auto;
    height: fit-content;
    width: fit-content;
  }

`
export const ImageContainer = styled.img`
  width: 100%;
  height: 100%px;
`
