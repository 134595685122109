import React from 'react'
import { LicensingBtnContainer } from './styles'

type Props = {
  children?: React.ReactNode,
  onClick?: () => void,
  theme: 'primary' | 'white' | 'blue-gradient'
}

const LicensingBtn: React.FC<Props> = ({
  children,
  onClick,
  theme = 'primary'
}): JSX.Element => {
  return (
    <LicensingBtnContainer
      onClick={onClick}
      background={theme}
      color="white"
    >
      {children}
    </LicensingBtnContainer>
  )
}

export default LicensingBtn
