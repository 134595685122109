import React from 'react'
import ConectaIcon from '../../../../assets/logos/service-logos/conecta-icon-otimizado.png'
import Rectangle1371Png from '../../../../assets/img/Rectangle1371-blue-otimizado.png'
import Img36Png from '../../../../assets/img/image36-otimizado.png'
import Img37Png from '../../../../assets/img/image37-otimizado.png'

import {
  IconTop,
  ContentService,
  InfoContainer,
  TitleInfoContent,
  BlockInfoText,
  ImgContentContainer,
  BackgroundImg,
  Text
} from '../../styles'
import PrimaryTextService from '../../PrimaryTextService'

type Props = {
  children?: React.ReactNode
}

const ConectaService: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <div>
      <IconTop src={ConectaIcon}/>
      <PrimaryTextService>
        Gere mais negócios de licenciamentos por meio do Destra
        <Text color="blue90"> Conecta</Text>
        </PrimaryTextService>
      <ContentService mobileColumnReverse>
        <InfoContainer>
          <TitleInfoContent textColor='blue90'>Marketplace</TitleInfoContent>
          <BlockInfoText>
            Sua marca disponível para inúmeras empresas licenciadas para gerar novas oportunidades em negócios. Conheça nosso marketplace: {' '}
            <a
              target={'_blank'}
              href='https://conecta.somosdestra.com/marca'
              rel="noreferrer"
            >
               https://conecta.somosdestra.com/marca
            </a>
          </BlockInfoText>
        </InfoContainer>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img36Png} width={'100%'}/>
          </div>
        </ImgContentContainer>
      </ContentService>
      <ContentService>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img37Png} width={'100%'} />
          </div>
        </ImgContentContainer>
        <InfoContainer>
          <TitleInfoContent textColor='blue90'>Prospecção Ativa</TitleInfoContent>
          <BlockInfoText>
            Além da nossa base com mais de 3 mil empresas licenciadas, contamos com ferramentas e um time comercial na busca ativa por novos negócios em licenciamento de marcas.
          </BlockInfoText>
        </InfoContainer>
      </ContentService>
    </div>
  )
}

export default ConectaService
