import styled from 'styled-components'

export const DivisorContainer = styled.div<{
  width?: string,
  color?: string
}>`
  width: ${props => props.width || '70%'};
  border: 0.5px solid ${props => props.color ? props.theme[props.color] : props.theme.gray30};
  /* background-color: ${props => props.theme.gray30}; */
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1px;
`
