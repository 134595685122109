import React from 'react'
import VectorSVG from '../../../assets/img/vectors/vector-down.svg'
import { Container } from './styles'

type Props = {
  children?: React.ReactNode,
  videoRef?: any,
}

const VectorDown: React.FC<Props> = ({
  children,
  videoRef
}): JSX.Element => {
  const scrollToVideo = () => {
    videoRef.current.scrollIntoView({
      block: 'center',
      inline: 'center'
    })
  }
  return (
    <Container src={VectorSVG} onClick={scrollToVideo} />
  )
}

export default VectorDown
