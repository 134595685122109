import React from 'react'
import LogosItem from '../../../Home/Partners/LogosItem'
import { LogosPartnersDisplayContainer } from './styles'
import { brandPartners, industryPartners } from '../../../../shared/constants'

type Props = {
  children?: React.ReactNode
  partner: 'brands' | 'industries'
}

const LogosPartnersDisplay: React.FC<Props> = ({
  children,
  partner
}): JSX.Element => {
  const getPartnerArray = () => partner === 'brands' ? brandPartners : industryPartners

  const renderLogo = (index: number, svg: any, hideInSmallViewPort = false, path?: string) => (
    <LogosItem key={index} src={svg} hideInSmallViewPort={hideInSmallViewPort} path={path} />
  )
  return (
    <LogosPartnersDisplayContainer>
      {
        getPartnerArray().map(
          ({ svg, hideInSmallViewPort, path }, index) => renderLogo(index, svg, hideInSmallViewPort, path)
        )
      }
    </LogosPartnersDisplayContainer>
  )
}

export default LogosPartnersDisplay
