import React, { useState } from 'react'
import Divisor from '../../../../components/_common/Divisor'
import FAQItemToggleButton from '../FAQItemToggleButton'
import { FAQContent, FAQItemContainer, FAQItemHeader, TitleItem } from './styles'

type Props = {
  children?: React.ReactNode,
  faq: any
}

const FAQItem: React.FC<Props> = ({
  faq
}): JSX.Element => {
  const [active, setActive] = useState(false)

  return (
    <>
      <FAQItemContainer>
        <FAQItemHeader>
          <TitleItem>{faq.title}</TitleItem>
          <FAQItemToggleButton setActive={setActive} active={active} />
        </FAQItemHeader>
        <FAQContent active={active}>{faq.content}</FAQContent>
      </FAQItemContainer>
    </>
  )
}

export default FAQItem
