import CentauroLogo from '../../../assets/logos/logo-guia-marcas-centauro-otimizado.png'
import FanaticsLogo from '../../../assets/logos/Fanatics-logo-otimizado.png'
import ImaginariumLogo from '../../../assets/logos/Imaginarium-logo-otimizado.png'
import PifferLogo from '../../../assets/logos/piffer-logo-otimizado.png'
import VascoLogo from '../../../assets/logos/vasco-da-gama-logo-otimizado.png'
import ZecaLogo2 from '../../../assets/logos/Zeca-logo-2-otimizado.png'
import SantosLogo from '../../../assets/logos/logo-guia-marcas-santos-otimizado.png'
import LucianoMartins from '../../../assets/logos/logo-guia-marcas-luciano-otimizado.png'

export const testimonials = [
  {
    text: [
      'A Destra é especialista no mercado de licenciamento e conta com profissionais altamente qualificados para facilitar e centralizar em uma única empresa a busca por oportunidades, a realização das negociações, a intermediação além do controle administrativo e financeiro através de seu sistema personalizado.',
      'Estamos muito satisfeitos com a nossa parceria!”'
    ],
    logoBrand: CentauroLogo,
    logoWidth: '150px',
    logoHeight: '50px',
    name: 'Christian Nagao',
    role: 'Diretor Categoria Futebol'
  },
  {
    text: [
      '“A Destra foi fundamental para alcançar os melhores parceiros no Brasil para que pudéssemos alavancar a marca PSG. A Destra é uma grande parceira com uma experiência local excepcional!"'
    ],
    logoBrand: FanaticsLogo,
    logoWidth: '150px',
    logoHeight: '50px',
    name: 'Younes Layati',
    role: 'Diretor de Licenciamentos',
    topDim: '40px'
  },
  {
    text: [
      '“Nosso objetivo foi desenvolver uma coleção que mostrasse o Natal “de verdade”. E foi aí que surgiu a ideia de fazer uma coleção com Zeca Pagodinho. Foi nesse instante que a Destra fez a diferença ao nos apoiar na negociação do contrato, na aprovação do conceito, no desenvolvimento dos produtos e nas respectivas aprovações e, por fim, na produção e captação do vídeo da campanha que aconteceu na casa do próprio Zeca. A coleção foi um sucesso e superou todas as expectativas.”'
    ],
    logoBrand: ImaginariumLogo,
    name: 'Caroline Serrano',
    role: 'Coordenadora de Novos Negócios'
  },
  {
    text: [
      '“A Destra sempre se mostrou solícita para atender aos nossos interesses. Na negociação para o licenciamento da marca do Paris Saint-Germain, por exemplo, seus representantes nos auxiliaram em todas as demandas que foram solicitadas. Isso nos gera extrema satisfação em relação a parceria. Contar com a marca do PSG mostra ao mercado de festas que temos o melhor em licenciamento para alcançar a maior variedade de públicos e fazendo com que momentos marcantes de suas vidas como as celebrações de aniversários se tornem ainda mais especiais. A Destra nos ajudou muito!”'
    ],
    logoBrand: PifferLogo,
    logoWidth: '150px',
    logoHeight: '50px',
    name: 'Rodrigo Lourenço',
    role: 'Líder de Marketing'
  },
  {
    text: [
      '“A Destra é uma empresa funcional. Seu software proporciona transparência, agilidade, acesso rápido, fácil com uma clareza resumida. Sou um artista e não sou chegado aos números e, ainda mais por isso, o software facilita encontrar os resultados que meu trabalho gera. Geramos muitos contratos atualmente e seria impossível acompanhar todo o processo. Isso sem contar no voluma de informações que são geradas e que me ajudam em novas prospecções. Trata-se de uma ferramenta muito boa e que me passa muita segurança.”'
    ],
    logoBrand: LucianoMartins,
    name: 'Luciano Martins',
    role: 'Artista Plástico'
  },
  {
    text: [
      '“As soluções da Destra foram divisoras de água aqui no Santos. Otimizamos nosso tempo e focamos no relacionamento com as empresas licenciadas. Conseguimos focar no que realmente importa: prospecção de novos negócios. Além de toda a tecnologia da plataforma que automatizou nossas cobranças e nossos controles de royalties, contamos com o apoio de profissionais extremamente capacitados e atenciosos, o que torna ainda melhor a relação com a Destra.”'
    ],
    logoBrand: SantosLogo,
    name: 'Debora Spagnoli',
    role: 'Licenciamento & Marketing'
  },
  {
    text: [
      '“A Destra nos ofereceu o suporte e soluções para a enorme demanda que tínhamos. E o melhor de tudo: como nossas necessidades eram quase que específicas, acabamos por construir em conjunto a solução que o Vasco necessitava.',
      'Com isso, tivemos uma evolução no cenário de licenciamento e que foi reconhecida pelos clubes. Posso atestar que a Destra não foi só importante, mas fundamental no processo que fez com que o Vasco aumentar sua captação de indústrias e consequentemente a arrecadação de royalties.”'
    ],
    logoBrand: VascoLogo,
    logoWidth: 50,
    logoHeight: 100,
    name: 'Fabio Lima',
    role: 'Coordenador de Marketing'
  },
  {
    text: [
      '“A parceria com a Destra tem sido muito interessante para nós. Ao longo desse tempo, fechamos parcerias importantes e desenvolvemos produtos que nos deixaram orgulhosos. Atualmente todos nossos processos estão mais ágeis e organizados quando se trata de licenciamento da marca.”'
    ],
    logoBrand: ZecaLogo2,
    logoWidth: 80,
    name: 'Louiz Carlos da Silva',
    role: 'Diretor de Marketing'
  }
]
