import React from 'react'
import { ContentNameEnum } from '../../../../../shared/enums'
import { ActiveContentObjType } from '../../../../../shared/types'

import {
  HoverContainer,
  IconBtn,
  OptionBtnContainer,
  OptionBtnResponsiveContainer,
  PositionLeft,
  ResponsiveContainer,
  TextBtn
} from './styles'

type Props = {
  children?: React.ReactNode,
  icon?: any,
  color?: string,
  contentName: ContentNameEnum,
  backgroundColor: string,
  activeContentObj: ActiveContentObjType,
  onClick: () => void
}

const OptionBtn: React.FC<Props> = ({
  children,
  icon,
  contentName,
  activeContentObj,
  backgroundColor,
  onClick
}): JSX.Element => {
  const isBtnActive = activeContentObj[contentName]

  return (
    <>
      <OptionBtnContainer
        backgroundColor={backgroundColor}
        onClick={onClick}
        active={isBtnActive}
      >
        <PositionLeft>
          <IconBtn
            backgroundColor={backgroundColor}
            active={isBtnActive}
            src={icon}
          />
          <TextBtn>{children}</TextBtn>
        </PositionLeft>
      </OptionBtnContainer>

      <ResponsiveContainer>
        <OptionBtnResponsiveContainer
          backgroundColor={backgroundColor}
          active={isBtnActive}
          onClick={onClick}
        >
          <TextBtn>{children}</TextBtn>
        </OptionBtnResponsiveContainer>
        <HoverContainer
          backgroundColor={backgroundColor}
          active={isBtnActive}
        />
      </ResponsiveContainer>
    </>
  )
}

export default OptionBtn
