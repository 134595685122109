import React from 'react'
import PrimaryText from '../../../components/PrimaryText'
import TitleText from '../../../components/TitleText'
import VectorRigth from '../../../components/_common/VectorRigth'
import { BaseConecta } from '../../../shared/constants'
import { navigateTo } from '../../../shared/helpers'
import LogosPartnersDisplay from './LogosPartnersDisplay'
import { ButtonPrimary, PartnersContainer, PartnersContent, SecundaryText, VectorText } from './styles'

type Props = {
  children?: React.ReactNode
}

const Partners: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <PartnersContainer>
      <PartnersContent flexDirection='column' alignItems='center'>
        <TitleText color="primary-gradient">Clientes</TitleText>
        <PrimaryText>Saiba quem são os nossos Clientes</PrimaryText>
        <SecundaryText>Conheça quem atingiu melhores resultados em seu departamento de licenciamento de marcas com nossas soluções</SecundaryText>
        <LogosPartnersDisplay />
        <ButtonPrimary
          onClick={() => navigateTo(`${BaseConecta}/marca`)}
        >
          Quero Licenciar
        </ButtonPrimary>
        <VectorText onClick={() => navigateTo(`${BaseConecta}/marca`)}>
          Conheça todas as marcas em nosso portfólio
          <VectorRigth />
        </VectorText>
      </PartnersContent>
    </PartnersContainer>
  )
}

export default Partners
