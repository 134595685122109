import React from 'react'
import { HeaderImgContainer, ImageBackgroud } from './styles'

type Props = {
  children?: React.ReactNode
  src: any
  height?: string
}

const HeaderImg: React.FC<Props> = ({
  children,
  src,
  height
}): JSX.Element => {
  return (
    <HeaderImgContainer>
      <ImageBackgroud height={height} src={src} />
    </HeaderImgContainer>
  )
}

export default HeaderImg
