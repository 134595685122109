import styled from 'styled-components'

export const ButtomServicesContainer = styled.button<{
  active: boolean
}>`
  width: 380px;
  height: 65px;
  border-radius: 15px 15px 0 0;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  @media screen and (max-width: 700px) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    gap: 5px;
  }

  ${
    props => props.active
      ? `
        background-color: ${props.theme.white};
        color: ${props.theme.primary}
      `
      : `
        background-color: transparent;
        color: ${props.theme.white}
      `
  }
`
