import React from 'react'
import { MainContent } from '../../../components/Footer/styles'
import Header from '../../../components/Header'
import MainContainer from '../../../components/_common/MainContainer'
import HeaderImg from '../../../components/HeaderImg'
import { ButtonContainer, Case1Container, ContainerHeader, ContainerImage, ContainerImage2, HeaderContent } from './styles'
import CaseDetalhes1 from '../../../assets/img/Case1-Vasco-Foto1.png'
import Case1Header from '../../../assets/img/banner_vasco.v3.png'
import Footer from '../../../components/Footer'
import Content from '../../../components/_common/Content'
import Button from '../../../components/Button'
import ContactUs from '../../../components/ContactUs'
import { BlockText, TextContainer } from '../../AboutUs/styles'

type Props = {
  children?: React.ReactNode
}

const Case1: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <>
      <MainContainer >
        <HeaderImg src={Case1Header} height={'560px'} />
        <Header />
        <MainContent flexDirection='column'>
          <ContainerHeader>
            <Content justifyContent='start'>
              <HeaderContent>
                Virada nos negócios: Vasco da Gama supera meta e arrecada R$ 6 milhões por ano com licenciamento
              </HeaderContent>
            </Content>
          </ContainerHeader>
          <Case1Container>
          <TextContainer>
            <BlockText>
                Fundado em 1898, o Club de Regatas Vasco da Gama é um dos grandes times do Brasil. Hoje, conta com a quinta maior torcida do país, segundo pesquisa do Ipec, que, aliada à tradição e história da instituição, agrega valores de mercado para a marca. Entretanto, com a grande quantidade de demandas, o Marketing do clube estava com dificuldades de gerenciar as questões de licenciamento da instituição. Para solucionar essa questão, passou a contar com a Destra, plataforma de LaaS (Licenciamento como Serviço) especializada em gerar e gerir negócios em licenciamento de marcas.
            </BlockText>
            <BlockText>
                Fábio Lima, Coordenador de Marketing do Vasco da Gama, que chegou ao cargo em junho de 2018, relata que a organização neste aspecto era difícil. “Quando me tornei o responsável pela parte de licenciamento, tinham muitas planilhas, todas separadas e tínhamos dificuldades de controlar esse processo. O clube está em uma transição, tornando-se uma SAF (Sociedade Anônima de Futebol), mas, antigamente, quando associação, não tínhamos braços suficientes para poder absorver toda a demanda de licenciamento”, pontua.
            </BlockText>
            <BlockText>
                Esse contexto vem se transformando por meio da parceria com a Destra, que tem um histórico de especialidade no licenciamento de marcas de clubes. “Buscamos fazer um acordo comercial que fosse interessante para as duas partes. Então, a partir disso, passamos a formatar e estabelecer os serviços, migrando todos os licenciados dentro da plataforma, que veio como um facilitador dessa mão de obra”, completa Fábio.
            </BlockText>
              <h2><b>Virada nos negócios</b></h2>
            <BlockText>
                A parceria entre o Vasco e a Destra mudou o nível de organização e arrecadação de licenciamento do Gigante da Colina. Apenas para ilustrar com números, o clube, anos atrás, contava com 14 empresas licenciadas, enquanto atualmente possui mais de 130. De 500 produtos licenciados, o número passou para mais de três mil. Quanto ao faturamento, o clube foi de R$ 800 mil a R$ 6 milhões por ano com seus produtos licenciados.
            </BlockText>
          </TextContainer>
          <ContainerImage2 >
            <img src={CaseDetalhes1} />
          </ContainerImage2>
          <TextContainer>
            <BlockText>
              Bruno Koerich, CEO da Destra, comenta sobre a parceria e a mudança de realidade que os serviços da Destra promoveram ao clube. “O Vasco da Gama é um dos gigantes do nosso futebol, então, naturalmente existia um potencial enorme que não vinha sendo aproveitado ao máximo. O que fizemos, basicamente, foi fornecer o suporte necessário para organizar as informações necessárias de forma que a instituição tivesse mais tempo e energia para trabalhar a marca com foco no estratégico e comercial”, diz. “Esperamos apoiá-los cada vez mais no processo de licenciamento e trazer ainda mais indústrias qualificadas ao clube”, conclui o CEO.
            </BlockText>
          </TextContainer>
          </Case1Container>
        </MainContent>
        <ContactUs />
      </MainContainer>
    </>
  )
}

export default Case1
