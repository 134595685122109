import React from 'react'
import { useNavigate } from 'react-router-dom'
import PrimaryText from '../../../../components/PrimaryText'
import TitleText from '../../../../components/TitleText'

import {
  SecundaryText,
  ButtonWhite,
  ResumeContainer,
  ButtonPrimary
} from './styles'

const AboutResume: React.FC<any> = ({
  videoRef
}): JSX.Element => {
  const navigate = useNavigate()

  const scrollToVideo = () => {
    videoRef.current.scrollIntoView({
      block: 'center',
      inline: 'center'
    })
  }

  return (
    <ResumeContainer>
      <TitleText color="primary-gradient">SOBRE A DESTRA</TitleText>
      <PrimaryText>Licenciamento de Marcas</PrimaryText>
      <SecundaryText>
        Gere negócios e gerencie seus contratos de licenciamento.
      </SecundaryText>

      <ButtonPrimary
        background='primary-gradient'
        onClick={() => navigate('/sobre')}
      >
        Saiba mais
      </ButtonPrimary>
      <ButtonWhite onClick={scrollToVideo}>
        Nosso Vídeo
      </ButtonWhite>
    </ResumeContainer>
  )
}

export default AboutResume
