import React from 'react'
import { PrimaryTextContainer } from './styles'

type Props = {
  children?: React.ReactNode
  colorText?: string
  fontSize?: string,
  fontWeight?: number,
  lineHeight?: string,
}

const PrimaryText: React.FC<Props> = ({
  children,
  colorText,
  fontSize,
  fontWeight,
  lineHeight
}): JSX.Element => {
  return (
    <PrimaryTextContainer
      colorText={colorText}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
    >
      {children}
    </PrimaryTextContainer>
  )
}

export default PrimaryText
