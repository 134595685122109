import React, { useState } from 'react'
import {
  CollapseContentContainer,
  ToggleButtonContainer,
  CollapsedMenu,
  LinksContainer,
  BtnContainer,
  LogoContainer,
  LinkItemOurServices,
  BrandIndustriSelect
} from './styles'
import LinkItem from '../../_common/LinkItem'
import ToggleButton from './ToggleBtn'
import LoginBtn from './LoginBtn'
import VectorRigth from '../../_common/VectorRigth'
import { LoginBtnItem, LinkItemContainer } from '../../_common/LinkItem/styles'
import LogoDestra from '../../LogoDestra'
import { routes } from '../../App/MakeRoutes/routes'
import { RouteNameEnum } from '../../../shared/enums'
import { navigateTo } from '../../../shared/helpers'
import ExternalLink from '../../_common/ExternalLink'
import LicensingBtn from './LicensingBtn'
import { LinkContainer } from '../../_common/LinkItem/Link/styles'

type Props = {
  theme: 'primary' | 'white'
}

const CollapseContent: React.FC<Props> = ({
  theme = 'primary'
}): JSX.Element => {
  const [active, setActive] = useState(false)

  const changeCollapsed = () => setActive(!active)

  const openLink = (url: string) => {
    navigateTo(url)
    setActive(false)
  }

  const makeLinksHeader = (
    isArrowEnable: boolean,
    hoverEffect: boolean,
    colorText: 'primary' | 'white'
  ) => routes.filter(route => !!route.headerLabel).map(
    (
      route,
      index: number
    ) => {
      if (route.path === RouteNameEnum.OUR_SERVICES) {
        return (
          <LinkItemOurServices colorText={theme}>
            <LinkItem theme={colorText} hoverEffect={false} key={index} to={'#'} >
              Nossas Soluções { isArrowEnable && <VectorRigth />}
            </LinkItem>
            <BrandIndustriSelect colorText={theme}>
              <LinkItemContainer color={theme}>
                <LinkContainer
                  color={colorText} to={`/${route.path}/marca`}
                  onClick={changeCollapsed}
                >
                  Para Marcas { isArrowEnable && <VectorRigth />}
                </LinkContainer>
                {
                  hoverEffect && <span></span>
                }
              </LinkItemContainer>
              <LinkItemContainer color={theme}>
                <LinkContainer
                  color={colorText} to={`/${route.path}/industria`}
                  onClick={changeCollapsed}
                >
                  Para Indústrias { isArrowEnable && <VectorRigth />}
                </LinkContainer>
                {
                  hoverEffect && <span></span>
                }
              </LinkItemContainer>
            </BrandIndustriSelect>
          </LinkItemOurServices>
        )
      }

      if (route.path.startsWith('http')) {
        return (
          <LinkItemContainer key={index} color={theme}>
            <LinkContainer
              color={colorText}
              to='#'
              onClick={() => openLink(route.path)}
            >
            { route.headerLabel } { isArrowEnable && <VectorRigth />}
            </LinkContainer>
            {
              hoverEffect && <span></span>
            }
          </LinkItemContainer>
        )
      }
      return (
        <LinkItemContainer key={index} color={theme}>
          <LinkContainer
            color={colorText} to={ route.path || '#' }
            onClick={changeCollapsed}
          >
          { route.headerLabel } { isArrowEnable && <VectorRigth />}
          </LinkContainer>
          {
            hoverEffect && <span></span>
          }
        </LinkItemContainer>
      )
    })

  return (
  <>
      <CollapseContentContainer>
        {
          makeLinksHeader(false, true, theme)
        }
        <LicensingBtn
          theme="blue-gradient"
          onClick={() => navigateTo('https://conecta.somosdestra.com/marca')}
        >
          Quero Licenciar
        </LicensingBtn>
        <LoginBtn theme={theme} onClick={() => navigateTo('https://performa.somosdestra.com')} >Login</LoginBtn>
      </CollapseContentContainer>

      <ToggleButtonContainer>
        <ToggleButton active={active} changeCollapsed={changeCollapsed} />
      </ToggleButtonContainer>
      {
        !active || (
          <CollapsedMenu>
            <LogoContainer>
              <LogoDestra type={theme} goToHome />
            </LogoContainer>
            <LinksContainer>
              <div>
                {
                  makeLinksHeader(true, false, 'primary')
                }
              </div>
            </LinksContainer>
            <BtnContainer>
              <LoginBtnItem>
                <LoginBtn
                  theme="primary"
                  onClick={() => openLink('https://performa.somosdestra.com') }
                >
                  Login
                </LoginBtn>
              </LoginBtnItem>
              <LoginBtnItem>
                <LicensingBtn
                  theme="blue-gradient"
                  onClick={() => openLink('https://conecta.somosdestra.com/marca')}
                >
                 Quero Licenciar
                </LicensingBtn>
              </LoginBtnItem>
            </BtnContainer>
          </CollapsedMenu>
        )
      }
    </>
  )
}

export default CollapseContent
