import React from 'react'
import {
  IconTop,
  ContentService,
  InfoContainer,
  TitleInfoContent,
  ImgContentContainer,
  BlockInfoText,
  BackgroundImg,
  Text
} from '../../styles'

import PerformaIcon from '../../../../assets/logos/service-logos/performa-icon-otimizado.png'
import Rectangle1371Png from '../../../../assets/img/Rectangle1371-warning-otimizado.png'
import Img33Png from '../../../../assets/img/image33-otimizado.png'
import Img34Png from '../../../../assets/img/image34-otimizado.png'
import Img35Png from '../../../../assets/img/image35-otimizado.png'
import PrimaryTextService from '../../PrimaryTextService'

type Props = {
  children?: React.ReactNode
}

const PerformaService: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <div>
      <IconTop src={PerformaIcon}/>
      <PrimaryTextService>
        <Text color="warning80">Performa</Text> para gerenciar seus contratos de licenciamento
      </PrimaryTextService>

      {/* Módulo 1 */}
      <ContentService mobileColumnReverse>
        <InfoContainer>
          <TitleInfoContent textColor='warning80'>Fechamento de Royalties</TitleInfoContent>
          <BlockInfoText>Realize fechamento de royalties para todos seus contratos de licenciamento com poucos cliques, reduzindo tempo e ganhando produtividade</BlockInfoText>
        </InfoContainer>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img33Png} width={'100%'}/>
          </div>
        </ImgContentContainer>
      </ContentService>

      {/* Módulo 2 */}
      <ContentService>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img34Png} width={'100%'}/>
          </div>
        </ImgContentContainer>
        <InfoContainer>
          <TitleInfoContent textColor='warning80'>Gestão de Contratos de Licenciamento</TitleInfoContent>
          <BlockInfoText>Realize a gestão de todos os contratos de licenciamento que você possui com marcas e não perca mais nenhum prazo. Gerencie a saúde dos seus contratos através de uma métrica criada pela plataforma Destra.
          <br/>
          <a target="_blank" href="https://www.youtube.com/watch?v=jAZ33CcKT3Y&list=PLW0h_ugGCJBO2jRjeItQSbo1UOkwr1Vr4&index=1" rel="noreferrer">
            Conheça mais em nosso vídeo
          </a>.</BlockInfoText>
        </InfoContainer>
      </ContentService>

      {/* Módulo 3 */}
      <ContentService mobileColumnReverse>
        <InfoContainer>
          <TitleInfoContent textColor='warning80'>Módulo Financeiro</TitleInfoContent>
          <BlockInfoText>Gerencie todos os royalties e garantia mínima que você tem a pagar de forma simples e fácil. Acompanhe todos os títulos liquidados e a vencer, sejam eles de royalties excedentes ou garantia mínima.
          </BlockInfoText>
        </InfoContainer>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img35Png} width={'100%'}/>
          </div>
        </ImgContentContainer>
      </ContentService>

      {/* Módulo 4 */}
      <ContentService>
        <ImgContentContainer >
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img33Png} width={'100%'}/>
          </div>
        </ImgContentContainer>
        <InfoContainer>
          <TitleInfoContent textColor='warning80'> Relatórios e Dashboard</TitleInfoContent>
          <BlockInfoText>Acompanhe os principais indicadores por meio de nossos dashboard especializados e relatórios: Royalties Gerados, Faturamento, Ranking de Produtos, Ranking de Licenças, DRE, entre outros.
          </BlockInfoText>
        </InfoContainer>
      </ContentService>

       {/* Módulo 5 */}
       <ContentService mobileColumnReverse>
        <InfoContainer>
          <TitleInfoContent textColor='warning80'> Mapa de Vendas</TitleInfoContent>
          <BlockInfoText>Visualize em nosso mapa onde seus produtos estão sendo comercializados.
          </BlockInfoText>
        </InfoContainer>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img34Png} width={'100%'}/>
          </div>
        </ImgContentContainer>
      </ContentService>
    </div>
  )
}

export default PerformaService
