import styled from 'styled-components'

type Props = {
  background?: string;
  color?: string;
  // type?: string;
}

export const ButtonContainer = styled.button<Props>`
  border: 0 ;
  background: ${props => props.background ? props.theme?.[props.background] : 'none'};
  color: ${props => props.color ? props.theme?.[props.color] : 'none'};
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  /* width: 86px; */
  height: 34px;
  top: 16px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
    transition: all ease-in-out .5s;
  }

  ${props =>
    props.background === 'white' && `
      background-color: transparent;
      border: 1px solid white;
    `
  }
`
