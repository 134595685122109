import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../shared/colors'

import { AppContainer } from './style'
import { BrowserRouter } from 'react-router-dom'
import MakeRoutes from './MakeRoutes'

import FloatingWhatsapp from '../../components/FloatingWhatsapp'
import Footer from '../Footer'
import Header from '../Header'
import MainContainer from '../_common/MainContainer'

const App = () => {
  return (
    <AppContainer>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <MainContainer>
            <Header />
            <MakeRoutes />
            <Footer />
            <FloatingWhatsapp />
          </MainContainer>
        </ThemeProvider>
      </BrowserRouter>
    </AppContainer>
  )
}

export default App
