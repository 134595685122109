import styled from 'styled-components'
import { ContentContainer } from '../../../components/_common/Content/styles'

export const CustomersTestimonialsContainer = styled(ContentContainer)`
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1120px;

  @media screen and (max-width: 1200px) {
    max-width: 700px;
  }
`
