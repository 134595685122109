import React from 'react'
import { ServiceTypeEnum } from '../../../../shared/enums'
import { SrcImagesObjType } from '../../../../shared/types'
import { ServiceTypeImgContainer, ServiceTypeImgContainerMobile } from './styles'

type Props = {
  children?: React.ReactNode
  activeService?: ServiceTypeEnum;
  type: ServiceTypeEnum;
  srcObj: SrcImagesObjType
}

const ServiceTypeImg: React.FC<Props> = ({
  children,
  type,
  srcObj,
  activeService
}): JSX.Element => {
  const isActiveServiceType = (typeToCheck: ServiceTypeEnum) => activeService === typeToCheck

  const handleSrcImg = () => isActiveServiceType(type) ? srcObj?.active : srcObj?.inactive
  const handleSrcImgMobile = () => isActiveServiceType(type) ? srcObj?.mobileActive : srcObj?.mobileInactive

  return (
    <>
      <ServiceTypeImgContainer src={handleSrcImg()}>
        {children}
      </ServiceTypeImgContainer>
      <ServiceTypeImgContainerMobile src={handleSrcImgMobile()}>
        {children}
      </ServiceTypeImgContainerMobile>
    </>
  )
}

export default ServiceTypeImg
