import React from 'react'
import VectorSVG from '../../../assets/img/vectors/vector-rigth.svg'
import { Container } from './styles'

type Props = {
  children?: React.ReactNode
}

const VectorRigth: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <Container src={VectorSVG}></Container>
  )
}

export default VectorRigth
