import styled from 'styled-components'

export const CasesContainer = styled.div`
  width: 100%;
  height: fit-content;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1300px) {
    margin: 30px auto;
  }

  @media screen and (max-width: 1000px) {
    margin: 10px auto;
  }

  @media screen and (max-width: 750px) {
    flex-direction: column;
    height: fit-content;
    align-items: center;
  }
`
export const Title = styled.div`
  text-align: center;
  margin: 100px auto;
  width: fit-content;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;

  div {
    margin-top: 24px;
    position: relative;
    left: 35%;
    width: 30%;
    height: 6px;
    background-color: ${props => props.theme.primary};
  }
`
export const Text = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  text-align: center;

  margin-bottom: 30px;
`

export const CaseTitle = styled.div<{color: string}>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme[`${props.color}70`]};
  text-transform: uppercase;
`

export const CaseText = styled.div`
  margin-top: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
`
