export const theme = {
  primary: '#4500A4',
  'primary-gradient': 'linear-gradient(90deg, #463499 0%, #5522D9 51.04%, #723ECC 100%)',
  'orange-gradient': 'linear-gradient(90deg, #E96E01 0%, #F18B30 100%)',
  'green-gradient': 'linear-gradient(90deg, #3A8D3D 0%, #44AB49 100%)',
  'blue-gradient': 'linear-gradient(180deg, #0081DF 0%, #00C5C5 100%)',

  // Support Colors
  white: '#FFFFFF',
  gray10: '#F4F4F4',
  gray20: '#EDEDED',
  gray30: '#DFDFDF',
  gray40: '#BCBCBC',
  gray50: '#9D9D9D',
  gray60: '#747474',
  gray70: '#606060',
  gray80: '#414141',
  gray90: '#202020',
  black: '#000000',

  // Auxiliary Colors

  // Purple
  purple: '#311B92',
  purple90: '#4527A0',
  purple80: '#512DA8',
  purple70: '#5E35B1',
  purple60: '#673AB7',
  purple50: '#7E57C2',
  purple40: '#9575CD',
  purple30: '#B39DDB',
  purple20: '#D1C4E9',
  purple10: '#EDE7F6',

  // Blue
  blue: '#0D47A1',
  blue90: '#1565C0',
  blue80: '#1976D2',
  blue70: '#0D47A1',
  blue60: '#2196F3',
  blue50: '#42A5F5',
  blue40: '#64B5F6',
  blue30: '#90CAF9',
  blue20: '#BBDEFB',
  blue10: '#E3F2FD',

  // Success
  success: '#39903C',
  success90: '#27BC86',
  success50: '#4CAF50',
  success40: '#66BB6A',
  success30: '#81C784',
  success20: '#A5D6A7',
  success10: '#C8E3C9',

  // Warning
  warning: '#D0680B',
  warning80: '#FF5F00',
  warning70: '#E16900',
  warning60: '#E26A00',
  warning50: '#F57F17',
  warning40: '#FBC02D',
  warning30: '#FFEB3B',
  warning20: '#FFF59D',
  warning10: '#FFF9C4',

  // Danger
  danger: '#991313',
  danger50: '#B71C1C',
  danger40: '#C62828',
  danger30: '#EF5350',
  danger20: '#EF9A9A',
  danger10: '#FFCDD2'
}
