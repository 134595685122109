import React from 'react'
import { Text, TitleTextContainer } from './styles'

type Props = {
  children?: React.ReactNode,
  color: string
}

const TitleText: React.FC<Props> = ({
  children,
  color
}): JSX.Element => {
  return (
    <TitleTextContainer color={color}>
      <Text>
        {children}
      </Text>
    </TitleTextContainer>
  )
}

export default TitleText
