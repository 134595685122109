import React from 'react'
import { RigthContentContainer } from './styles'

type Props = {
  children?: React.ReactNode
  justifyContent?: string
}

const RigthContent: React.FC<Props> = ({
  children,
  justifyContent
}): JSX.Element => {
  return (
    <RigthContentContainer justifyContent={justifyContent}>
      {children}
    </RigthContentContainer>
  )
}

export default RigthContent
