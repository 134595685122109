import React from 'react'
import { ContentContainer } from './styles'

type Props = {
  children?: React.ReactNode,
  flexDirection?: 'row' | 'column',
  justifyContent?: 'start' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch' | 'end',
  alignItems?: 'center' | 'start' | 'end' | 'self-start' | 'self-end' | 'flex-start' | 'flex-end',
  height?: string
  margin?: string
}

const Content: React.FC<Props> = ({
  children,
  flexDirection = 'row',
  justifyContent = 'center',
  alignItems = 'center',
  height = 'fit-content',
  margin = 'fit-content'
}): JSX.Element => {
  return (
    <ContentContainer
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      height={height}
      margin={margin}
    >
      {children}
    </ContentContainer>
  )
}

export default Content
