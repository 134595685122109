import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App'
import reportWebVitals from './reportWebVitals'
import './assets/css/index.css'

const container = document.getElementById('root')

if (!container) throw new Error('Failed to find the root element')

const root = ReactDOM.createRoot(container)
root.render(<App />)

reportWebVitals()
