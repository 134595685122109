import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { RouteAppType } from '../../../shared/types'
import Redirect from '../Redirect'
import { routes } from './routes'

type Props = {
  children?: React.ReactNode
}

const renderRoutes: any = (routes: RouteAppType[]) => routes.map((route, index) => {
  if (route.view) {
    return (
      <Route path={route.path} element={route.view && <route.view />} key={index} />
    )
  }

  if (route.redirect) {
    return (
      <Route path={route.path} element={<Navigate to={route.redirect} replace />}/>
    )
  }

  if (route.views) {
    return renderRoutes(route.views)
  }

  // if (route.scrollLink) {

  // }

  return <Route path={route.path} element={<Redirect url={route.path} />} key={index}/>
})

const MakeRoutes: React.FC<Props> = (): JSX.Element => {
  return (
    <Routes>
      {renderRoutes(routes)}
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
    </Routes>
  )
}

export default MakeRoutes
