import React, { useState } from 'react'
import Content from '../../../components/_common/Content'
import { ContentNameEnum } from '../../../shared/enums'
import { ActiveContentObjType } from '../../../shared/types'

import Ilustration from './Ilustration'
import InfoContent from './InfoContent'
import ButtonsContent from './LeftContent'
import { DiscoverOurServicesContainer } from './styles'

type Props = {
  children?: React.ReactNode
}

const DiscoverOurServices: React.FC<Props> = ({
  children
}): JSX.Element => {
  const [activeContentObj, setActiveContentObj] = useState<ActiveContentObjType>({
    industry: true,
    brand: true,
    // licensing: true,
    aboutDestra: true

  })

  const resetActiveContentObj = {
    industry: true,
    brand: true,
    licensing: true,
    aboutDestra: true
  }

  const changeActiveContent = (contentName: ContentNameEnum) => setActiveContentObj({
    ...resetActiveContentObj,
    [contentName]: true
  })
  return (
    <DiscoverOurServicesContainer>
      <Content>
        <InfoContent />
        <ButtonsContent
          activeContentObj={activeContentObj}
          changeActiveContent={changeActiveContent}
        />
      </Content>
    </DiscoverOurServicesContainer>
  )
}

export default DiscoverOurServices
