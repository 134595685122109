import styled from 'styled-components'

export const FAQContainer = styled.div`
  width: 100%;
  transition: height 25s linear;

`
export const Title = styled.div`
  text-align: center;
  margin: 100px auto;
  width: fit-content;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;

  div {
    margin-top: 24px;
    position: relative;
    left: 20%;
    width: 60%;
    height: 6px;
    background-color: ${props => props.theme.primary};
  }
`
