import styled from 'styled-components'

type Props = {
  onClick?: () => void;
}

export const FAQItemToggleButtonContainer = styled.div<Props>`
  background-color: ${props => props.theme.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 1px solid ${props => props.theme.black}
`

export const IconToggleBtn = styled.img<{ type: 'close' | 'open'}>`
  width: 60%;
  height: ${props => props.type === 'open' && '60%'};
`

export const Line = styled.div`
  border-bottom: 2px solid ${props => props.theme.black};
  border-radius: 1px;
  width: 10px;
  height: 2px;
  margin: 2px;
`

export const LineY = styled(Line)`
  height: 0;
  margin: 0;
  padding: 0;
  transform: rotate(90deg);
  width: 50%;
  position: relative;
  bottom: 20%;
`
