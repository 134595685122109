import React from 'react'
import { ButtomServicesContainer } from './styles'

type Props = {
  children?: React.ReactNode
  active: boolean
  onClick?: () => void
}

const ButtomServices: React.FC<Props> = ({
  children,
  active,
  onClick
}): JSX.Element => {
  return (
    <ButtomServicesContainer
      active={active}
      onClick={onClick}
    >
      {children}
    </ButtomServicesContainer>
  )
}

export default ButtomServices
