import styled from 'styled-components'

export const ExternalLinkContainer = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 16px;


  &:hover {
    span {
      width: 20px;
      height: 1px;
      background-color: ${props => props.theme.primary};

      position: absolute;
      bottom: 0px;
      transition: all .2s ease-in-out;
      transform: scale(2);
    }
  }

  @media screen and (max-width: 1200px) {
    text-align: start;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    display: block;
    margin: 16px 0;
    padding: 3px;
  }

  div {
    margin: 16px;
  }
`
