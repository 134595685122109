import styled from 'styled-components'

export const SocialMediaBannerContainer = styled.div`
  width: 100%;
  margin: auto;
  bottom: 0;
  display: flex;
  align-items: center;
  box-sizing: content-box;
  height: 69%;
`
export const ImgBanner = styled.img`
  width: 100%;
  height: 90%;
`
export const BannerIframeContainer = styled.div`
  width: 99%;
  height: 69%;
  display: flex;
  align-items: center;
`
export const BannerIframe = styled.iframe`
  width: 100%;
`
