import React from 'react'
import DestraPerformaActivePNG from '../../../assets/img/service-type/destra-performa-active-otimizado.png'
import DestraPerformaMobileActivePNG from '../../../assets/img/service-type/destra-performa-mobile-active-otimizado.png'
import DestraPerformaPNG from '../../../assets/img/service-type/destra-performa-otimizado.png'
import DestraPerformaMobilePNG from '../../../assets/img/service-type/destra-performa-mobile-otimizado.png'
import DestraTransformaActivePNG from '../../../assets/img/service-type/destra-transforma-active-otimizado.png'
import DestraTransformaMobileActivePNG from '../../../assets/img/service-type/destra-transforma-mobile-active-otimizado.png'
import DestraTransformaPNG from '../../../assets/img/service-type/destra-transforma-otimizado.png'
import DestraTransformaMobilePNG from '../../../assets/img/service-type/destra-transforma-mobile-otimizado.png'
import DestraConectaActivePNG from '../../../assets/img/service-type/destra-conecta-active-otimizado.png'
import DestraConectaPNG from '../../../assets/img/service-type/destra-conecta-otimizado.png'
import DestraConectaMobilePNG from '../../../assets/img/service-type/destra-conecta-mobile-otimizado.png'
import DestraConectaMobileActivePNG from '../../../assets/img/service-type/destra-conecta-mobile-active-otimizado.png'
import ButtomServices from './ButtomServices'
import { SelectServicesContainer } from './styles'
import ServiceTypeImg from './ServiceTypeImg'
import { ServiceTypeEnum } from '../../../shared/enums'
import { SrcImagesObjType } from '../../../shared/types'

type Props = {
  children?: React.ReactNode
  activeService: ServiceTypeEnum
  setActiveService: (type: ServiceTypeEnum) => void
}

const SelectIndustryServices: React.FC<Props> = ({
  children,
  activeService,
  setActiveService
}): JSX.Element => {
  const srcObjPerforma: SrcImagesObjType = {
    active: DestraPerformaActivePNG,
    inactive: DestraPerformaPNG,
    mobileActive: DestraPerformaMobileActivePNG,
    mobileInactive: DestraPerformaMobilePNG
  }

  const srcObjConecta: SrcImagesObjType = {
    active: DestraConectaActivePNG,
    inactive: DestraConectaPNG,
    mobileActive: DestraConectaMobileActivePNG,
    mobileInactive: DestraConectaMobilePNG
  }

  const srcObjTransforma: SrcImagesObjType = {
    active: DestraTransformaActivePNG,
    inactive: DestraTransformaPNG,
    mobileActive: DestraTransformaMobileActivePNG,
    mobileInactive: DestraTransformaMobilePNG
  }

  return (
    <SelectServicesContainer>
      <ButtomServices
        active={activeService === ServiceTypeEnum.PERFORMA}
        onClick={() => setActiveService(ServiceTypeEnum.PERFORMA)}
      >
          <ServiceTypeImg
            type={ServiceTypeEnum.PERFORMA}
            srcObj={srcObjPerforma}
            activeService={activeService}
          />
      </ButtomServices>
      <ButtomServices
        active={activeService === ServiceTypeEnum.CONECTA}
        onClick={() => setActiveService(ServiceTypeEnum.CONECTA)}

      >
          <ServiceTypeImg
            type={ServiceTypeEnum.CONECTA}

            srcObj={srcObjConecta}
            activeService={activeService}
          />
      </ButtomServices>
      <ButtomServices
        active={activeService === ServiceTypeEnum.TRANSFORMA}
        onClick={() => setActiveService(ServiceTypeEnum.TRANSFORMA)}
      >
          <ServiceTypeImg
            type={ServiceTypeEnum.TRANSFORMA}
            activeService={activeService}
            srcObj={srcObjTransforma}
          />
      </ButtomServices>
    </SelectServicesContainer>
  )
}

export default SelectIndustryServices
