import styled from 'styled-components'

export const MainContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  min-width: 360px;
  font-size: 1rem;
  margin-top: 80px;

  @media screen and (max-width: 360px) {
    overflow: visible;
  }

`
