import React from 'react'
import { PhoneNumber } from '../styles'
import { WhatsappPhoneContainer } from './styles'
import WhatsAppPNG from '../../../assets/img/whatsapp-otimizado.png'
import { navigateTo } from '../../../shared/helpers'

type Props = {
  children?: React.ReactNode
  phoneNumber: string
}

const WhatsappPhone: React.FC<Props> = ({
  children,
  phoneNumber
}): JSX.Element => {
  const onClick = () => navigateTo('https://api.whatsapp.com/send/?phone=554830474009&text=Ol%C3%A1%20quero%20saber%20mais%20sobre%20licenciamento&app_absent=0')

  return (
    <WhatsappPhoneContainer
      onClick={onClick}
    >
      <PhoneNumber>
        <img width={15} height={15} src={WhatsAppPNG} />
        {phoneNumber}
      </PhoneNumber>
    </WhatsappPhoneContainer>
  )
}

export default WhatsappPhone
