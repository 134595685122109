import React from 'react'
import { brandPartners, industryPartners } from '../../../../shared/constants'
import LogosItem from '../LogosItem'
import { LogosPartnersDisplayContainer } from './styles'

type Props = {
  children?: React.ReactNode
}

const renderLogo = (index: number, svg: any, hideInSmallViewPort = false, path?: string) => (
  <LogosItem key={index} src={svg} hideInSmallViewPort={hideInSmallViewPort} path={path} />
)

const LogosPartnersDisplay: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <LogosPartnersDisplayContainer>
      <div>
        {
          industryPartners.map(
            ({ svg, hideInSmallViewPort }, index) => renderLogo(index, svg, hideInSmallViewPort)
          )
        }
      </div>
      <div>
      {
          brandPartners.map(
            ({ svg, hideInSmallViewPort, path }, index) => renderLogo(index, svg, hideInSmallViewPort, path)
          )
        }
      </div>
    </LogosPartnersDisplayContainer>
  )
}

export default LogosPartnersDisplay
