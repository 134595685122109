import styled from 'styled-components'

export const ContentContainer = styled.div<{
  flexDirection?: 'row' | 'column',
  justifyContent?: 'start' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch' | 'end',
  alignItems?: 'center' | 'start' | 'end' | 'self-start' | 'self-end' | 'flex-start' | 'flex-end',
  height?: string
  margin?: string
  maxWidth?: string
}>`
  margin: ${props => props.margin};
  display: flex;
  max-width: ${props => props.maxWidth || '1120px'};
  height: ${props => props.height && props.height};
  flex-direction: ${props => props.flexDirection && props.flexDirection};
  align-items: ${props => props.alignItems && props.alignItems};
  justify-content: ${props => props.justifyContent && props.justifyContent};
  transition: ease-in-out all 0.5s;

  @media screen and (max-width: 1424px) {
    max-width: 1120px;
  }

  @media screen and (max-width: 1424px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 500px) {
    padding: 0 auto;
  }

`
