import React from 'react'
import PrimaryText from '../../../../components/PrimaryText'
import TitleText from '../../../../components/TitleText'
import {
  ButtonPrimary,
  ButtonsContainer,
  InfoContentContainer,
  SecundaryText,
  Text
} from './styles'

type Props = {
  children?: React.ReactNode
}

const InfoContent: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <InfoContentContainer>
      <TitleText color="primary-gradient">Conheça Nossos Serviços</TitleText>
      <PrimaryText>
        Seu <Text color="primary">braço direito</Text> em licenciamento de marcas
      </PrimaryText>
      <SecundaryText>
        Quando se fala de licenciamento de marcas, a Destra é referência no assunto. Temos soluções que vão otimizar toda a operação quando o assunto é Licenciamento de Marcas. </SecundaryText>

      {/* <ButtonsContainer>
        <ButtonPrimary
          color="primary-gradient"
        >
          Para Indústria
        </ButtonPrimary>
        <ButtonPrimary
          color="orange-gradient"
        >
          Para Marcas
        </ButtonPrimary>
        <ButtonPrimary
          color="green-gradient"
        >
          Portal do Licenciamento
        </ButtonPrimary>
      </ButtonsContainer> */}
    </InfoContentContainer>
  )
}

export default InfoContent
