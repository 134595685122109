import styled from 'styled-components'
import { ContentContainer } from '../../../components/_common/Content/styles'

export const ResumeContentContainer = styled(ContentContainer)`
  max-width: 1120px;
  margin: 60px auto 20px auto;

  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    max-width: 700px
  }
`

export const Container = styled.div`
  margin: 60px 0;
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

`
