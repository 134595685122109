import React from 'react'
import LogoDestra from '../LogoDestra'
import LeftContent from './LeftContent'
import MiddleContent from './MiddleContent'
import RigthContent from './RigthContent'
import InstagramSVG from '../../assets/img/social-media/instagram.svg'
import YoutubePNG from '../../assets/img/social-media/youtube.svg'
import TwiterSVG from '../../assets/img/social-media/twiter.svg'
import LinkedinSVG from '../../assets/img/social-media/linkedin.svg'

import {
  MainContent,
  FooterMainContainer,
  FooterSecundaryContainer,
  TitleContent,
  LinkContainer,
  Container,
  IconSvg,
  FlexContentCollumn,
  Center,
  ResponsiveFooterMainContainer,
  IconContainer,
  Position,
  LGPDContainer,
  ExternalLinkFooter
} from './styles'
import FooterDivisor from './FooterDivisor'
import { Link } from 'react-router-dom'
import Content from '../_common/Content'
import VectorRigth from '../_common/VectorRigth'
import { RouteNameEnum } from '../../shared/enums'
import { navigateTo } from '../../shared/helpers'
import WhatsappPhone from './WhatsappPhone'

type Props = {
  children?: React.ReactNode
}

const Footer: React.FC<Props> = ({
  children
}): JSX.Element => {
  const linkedinURL = 'https://www.linkedin.com/company/somosdestra/'
  const instagranURL = 'https://www.instagram.com/somosdestra'
  const twitterURL = 'https://twitter.com/somosdestra'
  const youtubeURL = 'https://www.youtube.com/channel/UCody8nJOfJZaNXYGy1mPUGQ'

  return (
    <>
      <FooterMainContainer>
        <FooterDivisor bottom={true} />
        <MainContent>
          <LeftContent>
            <LogoDestra goToHome type='primary' />
            <br />
            <div id="alert">
              Em atendimento às Resoluções BCB n° 269 de 01/12/2022 e 293 de 15/02/2023, que visam trazer mais transparência nas transações e capacidade de rastreabilidade aos usuários,
              informamos que o Asaas é a instituição responsável pelo provimento do Pix e das demais formas de pagamento, ou seja, pela intermediação de transações de pagamento
              realizadas na Destra Tecnologia LTDA.
            </div>
            <br />
          </LeftContent>
          <MiddleContent>
            <Center>
              <LinkContainer>
                <Link to={`/${RouteNameEnum.ABOUT_US}`}>Quem Somos</Link>
              </LinkContainer>
              <LinkContainer>
                <Link to={`/${RouteNameEnum.OUR_SERVICES}/marca`}>Nossas Soluções</Link>
              </LinkContainer>
              <LinkContainer>
                <ExternalLinkFooter onClick={() => navigateTo('https://somosdestra.com/blog/')}>
                  Blog
                </ExternalLinkFooter>
              </LinkContainer>
              <LinkContainer>
                <Link to={`/${RouteNameEnum.CONTACT_US}`}>Fale Conosco</Link>
              </LinkContainer>
              <LinkContainer>
                <Link to={`/${RouteNameEnum.FAQ}`}>FAQ</Link>
              </LinkContainer>
            </Center>
          </MiddleContent>
          <RigthContent justifyContent='end'>
            <Center>
              <TitleContent>Florianópolis - Santa Catarina - Brasil</TitleContent>
              <WhatsappPhone phoneNumber="+55 48 3047-4009"/>
            </Center>
          </RigthContent>
        </MainContent>
        <FooterDivisor top={true} />
        <FooterSecundaryContainer>
          <MainContent>
            <LeftContent>
              <Container>
                2013 - 2022 © Destra Tecnologia Ltda
              </Container>

            </LeftContent>
            <MiddleContent>
              <FlexContentCollumn>
                <IconSvg onClick={() => navigateTo(instagranURL)} src={InstagramSVG} />
                <IconSvg onClick={() => navigateTo(twitterURL)} src={TwiterSVG} />
                <IconSvg onClick={() => navigateTo(linkedinURL)} src={LinkedinSVG} />
                <IconSvg onClick={() => navigateTo(youtubeURL)} src={YoutubePNG} />
              </FlexContentCollumn>
            </MiddleContent>
            <RigthContent>
              <LGPDContainer
                onClick={() => navigateTo('https://somosdestra.com/wp-content/uploads/2021/08/lgpd-destra.pdf')}
              >
                LGPD | Política de Prvacidade
              </LGPDContainer>
            </RigthContent>
          </MainContent>
        </FooterSecundaryContainer>
      </FooterMainContainer>

      <ResponsiveFooterMainContainer>
        <FooterDivisor bottom={true} />
        <Content justifyContent='space-between'>
          <LogoDestra type='primary' />
          <IconContainer>
            <IconSvg
                onClick={() => navigateTo(youtubeURL)}
                src={YoutubePNG}
              />
            <IconSvg
              onClick={() => navigateTo(instagranURL)}
              heightIcon={5}
              widthIcon={5}
              src={InstagramSVG}
            />
            <IconSvg
              onClick={() => navigateTo(twitterURL)}
              heightIcon={5}
              widthIcon={5}
              src={TwiterSVG}
            />
            <IconSvg
              onClick={() => navigateTo(linkedinURL)}
              heightIcon={5}
              widthIcon={5}
              src={LinkedinSVG}
            />
          </IconContainer>
        </Content>
        <Content
          flexDirection='column'
          alignItems='start'
        >
          <Position margin="30px 10px">

            <LinkContainer>
              <Link to="/about-us">Quem Somos <VectorRigth /></Link>
            </LinkContainer>
            <LinkContainer>
              <Link to="/about-us">Nossas Soluções <VectorRigth /></Link>
            </LinkContainer>
            <LinkContainer>
              <ExternalLinkFooter onClick={() => navigateTo('https://somosdestra.com/blog/')}>
                Blog <VectorRigth />
              </ExternalLinkFooter>
            </LinkContainer>
            <LinkContainer>
              <Link to="/about-us">Fale Conosco <VectorRigth /></Link>
            </LinkContainer>
            <LinkContainer>
              <Link to="/faq">FAQ <VectorRigth /></Link>
            </LinkContainer>
          </Position>
        </Content>
        <Content
          flexDirection='column'
          alignItems='start'
        >
          <Position margin="10px 10px">
            <TitleContent>Florianópolis - Santa Catarina - Brasil</TitleContent>
            <WhatsappPhone phoneNumber="+55 48 3047-4009" />
          </Position>
        </Content>
        <FooterDivisor top={true} />
        <FooterSecundaryContainer>
          <Content
            flexDirection='column'
            justifyContent='space-around'
            height='100%'
          >
            <LGPDContainer
              onClick={() => navigateTo('https://somosdestra.com/wp-content/uploads/2021/08/lgpd-destra.pdf')}
            >
              LGPD | Política de Prvacidade
            </LGPDContainer>
            <div>
              2013 - 2022 © Destra Tecnologia Ltda
            </div>
          </Content>
        </FooterSecundaryContainer>
      </ResponsiveFooterMainContainer>
    </>
  )
}

export default Footer
