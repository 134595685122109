import styled from 'styled-components'
import { ButtonContainer } from '../../../../components/Button/styles'
import { PrimaryTextContainer } from '../../../../components/PrimaryText/styles'
import { H6Container } from '../../../../components/_common/headers/H6/styles'

export const InfoContentContainer = styled.div`
    max-width: 1120px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;

    @media screen and (max-width: 1200px) {
      margin: 0 auto;
      max-width: 500px;
      align-items: center;
      padding: 0 20px;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
      padding: 0 5px;
    }
`

// TODO: Rever Componentização destes Containers - Duplicados em ResumeContent/RigthContent

export const Text = styled(PrimaryTextContainer)<{color: string}>`
  display: contents;
  color: ${props => props.theme[props.color]}
`

export const VectorText = styled(H6Container)`
  margin: 10px 0px;
  color: ${props => props.theme.black}
`

export const SecundaryText = styled.div`
  margin: 10px 0px;
  color: ${props => props.theme.gray70};
  font-family: 'Inter';
  font-size: 22px;
  line-height: 36px;
`

export const Button = styled(ButtonContainer)`
  font-family: 'Inter';
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0em;

  margin: 32px 8px;
  width: fit-content;
  height: 52px;

  @media screen and (max-width: 600px) {
    /* margin: 10px 0; */
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    margin: 8px auto;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 500px) {
    width: 100%;
    flex-direction: column;
  }
`

export const ButtonPrimary = styled(Button)`
  background: ${props => props.color && props.theme[props.color]};
  color: ${props => props.theme.white};
`

export const ButtonWhite = styled(Button)`
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.black};
  border: 1px solid ${props => props.theme.gray30};
  font-size: 1rem;
`

export const ResumeContainer = styled.div`
  height: 100%;
  width: 100%;
`
// TODO: FIM
