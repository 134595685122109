import styled from 'styled-components'
import { ContentContainer } from '../../../components/_common/Content/styles'

export const Case1Container = styled.div`

`
export const HeaderContent = styled.div`
  max-width: 700px;
  height: fit-content;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 68px;
  color: ${props => props.theme.white};
  text-align: left;

  display: flex;
  flex-direction: column;
`
export const ButtonContainer = styled.div`
  margin-bottom: 20px;
`

export const ContainerHeader = styled.div`
  width: 100%;
  height: 560px;
  display: flex;
  align-items: center;
`
export const ContainerImage = styled(ContentContainer)`
  margin-top: 50px;
  img {
    width: 100%;
    border-radius: 10px;
  }
`

export const ContainerImage2 = styled(ContentContainer)`
  margin-top: 50px;
  img {
    width: 60%;
    margin-left: 20%;
    border-radius: 10px;
  }
`
