import React from 'react'
import CloseIcon from '../../../../assets/img/close-icon-otimizado.png'
import OpenIcon from '../../../../assets/img/open-icon-otimizado.png'
import { FAQItemToggleButtonContainer, IconToggleBtn } from './styles'

type Props = {
  children?: React.ReactNode
  active: boolean
  setActive: (active: boolean) => void
}

const FAQItemToggleButton: React.FC<Props> = ({
  children,
  active,
  setActive
}): JSX.Element => {
  return (
    <FAQItemToggleButtonContainer onClick={() => setActive(!active)}>
      {
        active
          ? (
          <IconToggleBtn type="close" src={CloseIcon} />
            )
          : (
          <IconToggleBtn type="open" src={OpenIcon} />
            )
      }
    </FAQItemToggleButtonContainer>
  )
}

export default FAQItemToggleButton
