import styled from 'styled-components'
import { ButtonContainer } from '../../../../../components/Button/styles'
import { H7Container } from '../../../../../components/_common/headers/H7/styles'

type Props = {
  active: boolean
  backgroundColor: string
}

export const OptionBtnContainer = styled(ButtonContainer)<Props>`
  display: flex;
  color: ${props => props.active ? props.theme.white : props.theme.gray30};
  border: ${props => !props.active && (`1px solid ${props.theme.gray30}`)};
  background: ${props => props.active ? props.theme[props.backgroundColor] : props.theme.white};
  width: 238px;
  height: 96px;
  text-align: start;
  border-radius: 6px;
  margin: 10px 0px;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`
export const ResponsiveContainer = styled.div`
  display: none;

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 0 5px;
    align-items: center;
    text-align: center;
    height: 60px;
  }
`

export const OptionBtnResponsiveContainer = styled(ButtonContainer)<Props>`

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: center;

    /* Create the gradient. */
    background-image: ${props => props.active && props.theme[props.backgroundColor]};
    /* Set the background size and repeat properties. */
    background-size: ${props => props.active && '100%'};
    background-repeat: ${props => props.active && 'repeat'};
    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: ${props => props.active && 'text'};
    -webkit-text-fill-color: ${props => props.active && 'transparent'};
    -moz-background-clip: ${props => props.active && 'text'};
    -moz-text-fill-color: ${props => props.active && 'transparent'};

    color: ${props => !props.active && props.theme.gray30};

    width: 100%;

    padding: 0 auto;

    height: 36px;
  }
`

export const HoverContainer = styled.div<Props>`
  background: ${props => props.active ? props.theme[props.backgroundColor] : props.theme.gray30};
  height: ${props => props.active ? 8 : 4}px;
  margin-top: ${props => props.active ? 6 : 10}px;
  width: 100%;
`

export const IconBtn = styled.img<Props>`
  background: ${props => props.active ? props.theme[props.backgroundColor] : props.theme.gray30};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-right: 10px;
  width: 28px;
  padding: 8px;
`

export const PositionLeft = styled.div`
  display: flex;
  text-transform: uppercase;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const TextBtn = styled(H7Container)`
  text-align: left;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;

`
