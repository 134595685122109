import styled from 'styled-components'
import { ButtonContainer } from '../../../../components/Button/styles'
import { H6Container } from '../../../../components/_common/headers/H6/styles'

export const RigthContentContainer = styled.div`
  height: 100%;
  width: 1000px;
  text-align: start;
  margin-left: 40px;
  overflow: auto;

  @media screen and (max-width: 1200px) {
    max-width: max-content;
    margin-left: 0;

  }

  @media screen and (max-width: 800px) {
    width: 100%;

  }
`

export const VectorText = styled(H6Container)`
  margin: 10px 0px;
  color: ${props => props.theme.black}
`

export const SecundaryText = styled(H6Container)`
  margin: 10px 0px;
  word-break: break-word;
  color: ${props => props.theme.gray70};
`

export const Button = styled(ButtonContainer)`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;

  margin: 32px 8px;
  width: 256px;
  height: 52px;

  @media screen and (max-width: 630px) {
    margin: 10px 0;
    width: 100%;
  }
`

export const ButtonPrimary = styled(Button)`
  background: ${props => props.color && props.theme[props.color]};
  color: ${props => props.theme.white};
`

export const ButtonWhite = styled(Button)`
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.black};
  border: 1px solid ${props => props.theme.gray30};
  font-size: 1rem;
`

export const ResumeContainer = styled.div`
  height: 100%;
  width: 100%;
`

export const IframeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const Iframe = styled.iframe`
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 90%;

  @media screen and (max-width: 1200px) {
    position: relative;
    width: 80vw;
    height: 50vh;

  }
`
