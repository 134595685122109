import React from 'react'
import { useNavigate } from 'react-router-dom'
import PrimaryText from '../../../../components/PrimaryText'
import TitleText from '../../../../components/TitleText'
import VectorRigth from '../../../../components/_common/VectorRigth'

import {
  SecundaryText,
  VectorText,
  ButtonWhite,
  ResumeContainer,
  ButtonPrimary
} from './styles'

const IndustryResume: React.FC = (): JSX.Element => {
  const navigate = useNavigate()

  const navigateTo = (url: string) => {
    window.location.href = url
  }

  return (
    <ResumeContainer>
      <TitleText color="blue-gradient">Sou Indústria</TitleText>
      <PrimaryText>Venda mais com licenciamento</PrimaryText>
      <SecundaryText>Tenha grandes marcas nos seus produtos</SecundaryText>

      <ButtonPrimary
        color="blue-gradient"
        onClick={() => navigateTo('https://conecta.somosdestra.com/marca')}
      >
        Quero licenciar
      </ButtonPrimary>
      <ButtonWhite onClick={() => navigate('/nossos-servicos/industria')} >
        Gerencie seus contratos
      </ButtonWhite>

    </ResumeContainer>
  )
}

export default IndustryResume
