import styled from 'styled-components'

export const CarouselContainer = styled.div<{ qtdTotalItems?: number, currentItem: number }>`


  .slider {
    display: flex;
    align-items: center;
  }

  .slider-wrapper {
      height: 550px;
    }

  .carousel .control-dots {
    position: relative;
    top: 480px;
    z-index: 2;
  }

  width: 100%;
  height: fit-content;
  margin: 40px 0;
  text-align: center;
  padding: auto 0;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`
export const CurrentItem = styled.div`
  display: flex;
  margin: 0 10px;
  height: fit-content;
  background: ${props => props.theme.white};
  margin: auto 5px;
  position: relative;
  word-wrap: break-word;
  text-overflow: clip;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => props.theme.gray40};
  box-shadow: 2px 5px 5px ${props => props.theme.gray40};
  border-radius: 8px;
`

// export const Container

export const Item = styled.div<{topDim?: string}>`
  display: flex;
  word-wrap: break-word;
  height: fit-content;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  top: ${props => props.topDim};
  opacity: 0.2;
  border: 1px solid ${props => props.theme.black};
`
export const CarouselContainerResp = styled.div`

  display: none;

  @media screen and (max-width: 1200px) {
    display: block;

    .slider {
      display: flex;
      align-items: center;
    }

    .carousel-slider {
      height: fit-content;
    }

    .carousel .control-dots {
      z-index: 2;
    }

    width: 100%;
    height: fit-content;
    text-align: center;

    div {
      padding: 5px 0;

    }
  }
`
export const BlockText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 10px 32px;
  height: 80%;
`
export const Text = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  margin: 5px;
  color: ${props => props.theme.gray80}
`

export const NameRoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  align-items: left;
`

export const NameText = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.gray80}
`

export const RoleText = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: ${props => props.theme.gray50}
`

export const FooterTestimonial = styled.div`
  margin: 10px 40px;
  display: flex;
  position: relative;
  bottom: 0;
  justify-content: space-between;

  @media screen and (max-width: 580px) {
    flex-direction: column-reverse;

    div {
      margin: 20px auto;
    }
  }
`

export const LogoContainer = styled.div<{
  width?: string,
  height?: string
}>`
  width: ${props => props.width || '50px'};
  height: ${props => props.height || '50px'};
  position: relative;
  display: flex;
  align-items: center;
`
export const ContainerItem = styled.div`
  height: 100%;
  padding: 30px 0;
`
