import React, { ReactNode, useState } from 'react'
import { BlockText, CarouselContainer, CarouselContainerResp, ContainerItem, CurrentItem, FooterTestimonial, Item, LogoContainer, NameRoleContainer, NameText, RoleText, Text } from './styles'
import { Carousel as CarouselResponsive } from 'react-responsive-carousel'

type Props = {
  children?: React.ReactNode,
  data: any[]
}

const Carousel: React.FC<Props> = ({
  children,
  data
}): JSX.Element => {
  const [currentItem, setCurrentItem] = useState(0)
  const qtdTotalItems = data.length

  const updateCurrentSlide = (index: number) => {
    if (currentItem !== index) {
      setCurrentItem(index)
    }
  }

  const renderTestimonials = (data: any) => (
    <div>
      <BlockText>
        {
          data.text.map((item: string, index: number) => <Text key={index}>{item}</Text>)
        }
      </BlockText>
      <FooterTestimonial>
        <LogoContainer
          height={data.logoHeight}
          width={data.logoWidth}
        >
          <img src={data.logoBrand} />
        </LogoContainer>
        <NameRoleContainer>
          <NameText>{data.name}</NameText>
          <RoleText>{data.role}</RoleText>
        </NameRoleContainer>
      </FooterTestimonial>
    </div>
  )

  const renderItens = (item: React.ReactNode, options?: any): ReactNode => {
    if (options?.isSelected) {
      return (
        <CurrentItem>{item}</CurrentItem>
      )
    }

    return <Item>{item}</Item>
  }
  return (
    <>
      <CarouselContainer qtdTotalItems={qtdTotalItems} currentItem={currentItem}>
        <CarouselResponsive
          centerMode
          infiniteLoop
          autoPlay={true}
          showArrows={true}
          // animationHandler={'fade'}
          interval={5000}
          transitionTime={500}
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          centerSlidePercentage={50}
          renderItem={(item, options) => renderItens(item, options)}
          onClickItem={updateCurrentSlide}
          selectedItem={currentItem}
          onChange={updateCurrentSlide}
        >
          {data.map((item, index) => (
            <ContainerItem key={index}>
              {renderTestimonials(item)}
            </ContainerItem>
          ))}
        </CarouselResponsive>
      </CarouselContainer>

      <CarouselContainerResp>
        <CarouselResponsive
          // centerMode
          infiniteLoop
          emulateTouch
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          // centerSlidePercentage={80}
          renderItem={(item, options) => renderItens(item, options)}
          onClickItem={updateCurrentSlide}
          selectedItem={currentItem}
          onChange={updateCurrentSlide}
        >
          {data.map(item => (
            <>
              {renderTestimonials(item)}
            </>
          ))}
        </CarouselResponsive>
      </CarouselContainerResp>
    </>
  )
}

export default Carousel
