import React from 'react'
import Content from '../_common/Content'
import { VideoContentContainer } from './styles'
import ReactPlayer from 'react-player'

type Props = {
  children?: React.ReactNode
  videoRef?: any
}

const VideoContent: React.FC<Props> = ({
  videoRef
}): JSX.Element => {
  return (
    <VideoContentContainer>
      <Content>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=nMYpPaWnKMY"
        light="https://img.youtube.com/vi/nMYpPaWnKMY/maxresdefault.jpg"
        width="1024px" height="600px"
        />
      </Content>
    </VideoContentContainer>
  )
}

export default VideoContent
