import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ExternalLinkContainer } from '../_common/ExternalLink/styles'

export const FooterMainContainer = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 650px) {
    display: none
  }
`
export const MainContent = styled.div<{
  flexDirection?: 'row' | 'column'
  justifyContent?: 'start' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch' | 'end',
  alignItems?: 'center' | 'start' | 'end' | 'self-start' | 'self-end' | 'flex-start' | 'flex-end',
  height?: string
  width?: string
  margin?: string
}>`
  margin: ${props => props.margin ? props.margin : '0px auto'};
  max-width: 1120px;
  height: ${props => props.height ? props.height : '100%'};
  display: flex;
  flex-direction: ${props => props.flexDirection && props.flexDirection};

  @media screen and (max-width: 1424px) {
    max-width: 1120px;
    padding: 0 100px;
  }

  @media screen and (max-width: 1000px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 500px) {
    padding: 0 5px;
  }
`

export const FooterSecundaryContainer = styled.div`
  height: 84px;
  width: 100%;
`
export const LinkContainer = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 131.23%;
  margin: 15px 0;

  a {
    text-decoration: none;
    color: ${props => props.theme.black}
  }

  @media screen and (max-width: 650px) {
    font-size: 18px;
  }
`

export const TitleContent = styled.div`
  margin-bottom: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: flex;

  justify-content: start;

`

export const TextContent = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: start;
  line-height: 21px;

  img {
    margin-right: 5px;
  }
`

export const PhoneNumber = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: start;
  line-height: 21px;
  margin-top: 2px;

  img {
    margin-right: 5px;
  }

  @media screen and (max-width: 650px) {
    font-size: 16px
  }
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
`

export const IconSvg = styled.img<{
  heightIcon?: number,
  widthIcon?: number,
  onClick?: () => void,
}>`
  padding: 0;
  margin: 5px 0;
  height: ${props => props.heightIcon || '20%'};
  width: ${props => props.widthIcon || '20%'};
  cursor: pointer;
`

export const Center = styled.div`
  width: max-content;
  text-align: left;
`

export const FlexContentCollumn = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const ResponsiveFooterMainContainer = styled.div`
  display: none;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
  }
`
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;

  width: 140px;

  img {
    margin: 0 10px;

    @media screen and (max-width: 450px) {
      margin: 0 5px;
      /* width: 50%; */

    }
  }
`

export const Position = styled.div<{
  margin: string,
}>`
  margin: ${props => props.margin};
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
`

export const LGPDContainer = styled.div<{
  onClick?: () => void
}>`
  cursor: pointer;
`

export const ExternalLinkFooter = styled(ExternalLinkContainer)`
  justify-content: left;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 131.23%;
  margin: 0;

  @media screen and (max-width: 650px) {
    font-size: 16px;
  }

`
