import React from 'react'
import { ReactComponent as WhatsappSVG } from '../../assets/img/whatsapp.svg'
import { FloatingWhatsappContainer } from './styles'

type Props = {
  children?: React.ReactNode
}

const FloatingWhatsapp: React.FC<Props> = (): JSX.Element => {
  return (
    <FloatingWhatsappContainer>
      <a href="https://web.whatsapp.com/send?phone=554830474009" target="_blank" rel="noreferrer">
          <WhatsappSVG title="Conversar pelo WhatsApp" viewBox='0 0 37 37'/>
      </a>
    </FloatingWhatsappContainer>
  )
}

export default FloatingWhatsapp
