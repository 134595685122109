import React from 'react'
import { MainContentContainer } from './styles'

type Props = {
  children?: React.ReactNode
}

const MainContent: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <MainContentContainer>
      {children}
    </MainContentContainer>
  )
}

export default MainContent
