import React from 'react'
import LogoDestra from '../LogoDestra'
import Divisor from '../_common/Divisor'
import CollapseContent from './CollapseContent'
import {
  HeaderContainer,
  ResponsiveContainer,
  ResponsiveLogo
} from './style'

type Props = {
  theme?: 'primary' | 'white'
}

const Header: React.FC<Props> = ({
  theme = 'primary'
}): JSX.Element => {
  return (
    <HeaderContainer>
      <ResponsiveContainer>
        <ResponsiveLogo>
          <LogoDestra goToHome type={theme} />
        </ResponsiveLogo>
        <CollapseContent theme={theme} />
      </ResponsiveContainer>
      <Divisor width='100%' color='gray20' />
    </HeaderContainer>
  )
}

export default Header
