import React from 'react'
import { useNavigate } from 'react-router-dom'
import LogoPrimarySVG from '../../assets/logos/logo-destra/logo-destra-primary.svg'
import LogoWhiteSVG from '../../assets/logos/logo-destra/logo-destra-white.svg'

import { LogoDestraContainer } from './styles'

type Props = {
  type: 'primary' | 'white',
  goToHome?: boolean
}

const LogoDestra: React.FC<Props> = ({ type, goToHome }): JSX.Element => {
  const navigate = useNavigate()

  const handlerLogoType = () => {
    switch (type) {
      case 'primary':
        return LogoPrimarySVG
      case 'white':
        return LogoWhiteSVG
      default:
        return LogoPrimarySVG
    }
  }

  return <LogoDestraContainer
    src={handlerLogoType()}
    onClick={() => goToHome && navigate('/')}
  />
}

export default LogoDestra
