import React from 'react'
import { MainContent } from '../../../components/Footer/styles'
import Header from '../../../components/Header'
import MainContainer from '../../../components/_common/MainContainer'
import HeaderImg from '../../../components/HeaderImg'
import { ButtonContainer, Case1Container, ContainerHeader, ContainerImage, ContainerImage2, HeaderContent } from './styles'
import CaseDetalhes1 from '../../../assets/img/cases/case4_detalhe.png'
import Case1Header from '../../../assets/img/cases/case4_header.png'
import Footer from '../../../components/Footer'
import Content from '../../../components/_common/Content'
import Button from '../../../components/Button'
import ContactUs from '../../../components/ContactUs'
import { BlockText, TextContainer } from '../../AboutUs/styles'

type Props = {
  children?: React.ReactNode
}

const Case4: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <>
      <MainContainer >
        <HeaderImg src={Case1Header} height={'560px'} />
        <Header />
        <MainContent flexDirection='column'>
          <ContainerHeader>
            <Content justifyContent='start'>
              <HeaderContent>
                Herbíssimo e Mentos lançam linha exclusiva para banho com cheiro de balas
              </HeaderContent>
            </Content>
          </ContainerHeader>
          <Case1Container>
            <TextContainer>
              <BlockText>
                A PVM (Perfetti Van Melle), empresa responsável pelas marcas Mentos e Chupa Chups, anunciou para o mercado brasileiro: o lançamento da nova linha exclusiva para banho em parceria com a Herbíssimo.
              </BlockText>
              <BlockText>
                Com o objetivo de oferecer aos consumidores produtos de qualidade e que remetem ao sabor único de Mentos, a Herbíssimo desenvolveu uma linha de sabonetes, xampus e desodorantes que prometem agradar a todos os gostos.
              </BlockText>
              <BlockText>
                O licenciamento da marca Mentos é um passo importante para a empresa, que busca ampliar sua atuação em novos segmentos e atender às necessidades de um público cada vez mais exigente. Além disso, a parceria com a Herbíssimo reforça o compromisso da PVM em trabalhar com empresas que defendem os mesmos valores e princípios.
              </BlockText>
            </TextContainer>
            <ContainerImage2 >
              <img src={CaseDetalhes1} />
            </ContainerImage2>
            <TextContainer>
              <BlockText>
                Os produtos Mentos da Herbíssimo estarão disponíveis em diversas lojas do segmento de higiene pessoal, proporcionando aos consumidores uma experiência única e refrescante, com presentes que remetem aos sabores mais populares da marca.
              </BlockText>
              <BlockText>
                A Destra, empresa responsável por representar as marcas da PVM no Brasil para licenciamento, foi quem intermediou a negociação entre a Herbíssimo e a PVM, e está animada com o lançamento dos novos produtos.
              </BlockText>
              <BlockText>
                Com a chegada dos produtos Mentos da Herbíssimo, os consumidores brasileiros terão mais opções de produtos de higiene pessoal com experiências únicas e refrescantes, garantindo uma experiência completa e inesquecível.
              </BlockText>
            </TextContainer>
          </Case1Container>
        </MainContent>
        <ContactUs />
      </MainContainer>
    </>
  )
}

export default Case4
