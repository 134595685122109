import React from 'react'
import {
  IconTop,
  ContentService,
  InfoContainer,
  TitleInfoContent,
  ImgContentContainer,
  BlockInfoText,
  BackgroundImg,
  Text
} from '../../styles'

import PerformaIcon from '../../../../assets/logos/service-logos/performa-icon-otimizado.png'
import Rectangle1371Png from '../../../../assets/img/Rectangle1371-warning-otimizado.png'
import Img33Png from '../../../../assets/img/image33-otimizado.png'
import Img34Png from '../../../../assets/img/image34-otimizado.png'
import Img35Png from '../../../../assets/img/image35-otimizado.png'
import Img36Png from '../../../../assets/img/image36-otimizado.png'
import PrimaryTextService from '../../PrimaryTextService'

type Props = {
  children?: React.ReactNode
}

const PerformaService: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <div>
      <IconTop src={PerformaIcon}/>
      <PrimaryTextService>
        <Text color="warning80">Performa</Text> para atingir melhores resultados nos seus contratos de licenciamento
      </PrimaryTextService>
      <ContentService mobileColumnReverse>
        <InfoContainer>
          <TitleInfoContent textColor='warning80'>Módulo de Produtos</TitleInfoContent>
          <BlockInfoText>Realize a aprovação de produtos de forma simples e fácil. </BlockInfoText>
        </InfoContainer>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img33Png} width={'100%'} />
          </div>
        </ImgContentContainer>
      </ContentService>
      <ContentService>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img34Png} width={'100%'} />
          </div>
        </ImgContentContainer>
        <InfoContainer>
          <TitleInfoContent textColor='warning80'>Módulo de Contratos</TitleInfoContent>
          <BlockInfoText>
            Realize a gestão de todos os contratos de licenciamento que você possui com suas empresas licenciadas e não perca mais nenhum prazo. Gerencie a saúde dos seus contratos através de uma métrica criada pela plataforma Destra. Conheça mais em nosso
            {' '}
            <a
              target={'_blank'}
              href='https://www.youtube.com/watch?v=jAZ33CcKT3Y&list=PLW0h_ugGCJBO2jRjeItQSbo1UOkwr1Vr4&index=1' rel="noreferrer">
              vídeo
            </a>.
          </BlockInfoText>
        </InfoContainer>
      </ContentService>
      <ContentService mobileColumnReverse>
        <InfoContainer>
          <TitleInfoContent textColor='warning80'>Módulo Financeiro</TitleInfoContent>
          <BlockInfoText>
            Gerencie todos os royalties e garantia mínima que você tem a receber de forma simples e fácil. Acompanhe todos os títulos liquidados, inadimplência e demais informações inerentes ao financeiro do seu departamento de licenciamento. Saiba mais em nosso
            {' '}
            <a
              target={'_blank'}
              href='https://www.youtube.com/watch?v=vEPEAInuTEo&list=PLW0h_ugGCJBO2jRjeItQSbo1UOkwr1Vr4&index=2' rel="noreferrer">
              vídeo
            </a>.
          </BlockInfoText>
        </InfoContainer>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img35Png } width={'100%'}/>
          </div>
        </ImgContentContainer>
      </ContentService >
      <ContentService>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img34Png} width={'100%'}/>
          </div>
        </ImgContentContainer>
        <InfoContainer>
          <TitleInfoContent textColor='warning80'>Fechamento de Royalties</TitleInfoContent>
          <BlockInfoText>
          Por meio da nossa plataforma você consegue controlar as vendas realizadas pelas empresas licenciadas, através da leitura das Notas Fiscais Eletrônicas, gerando relatórios de royalties mais confiáveis.
          </BlockInfoText>
        </InfoContainer>
      </ContentService>

      <ContentService mobileColumnReverse>
        <InfoContainer>
          <TitleInfoContent textColor='warning80'>Relatórios e Dashboard</TitleInfoContent>
          <BlockInfoText>
          Acompanhe os principais indicadores por meio de nossos dashboard especializados e relatórios: Royalties Gerados, Faturamento, Ranking de Produtos, Ranking de Licenças, DRE, entre outros.
          </BlockInfoText>
        </InfoContainer>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img36Png} width={'100%'}/>
          </div>
        </ImgContentContainer>
      </ContentService>

      <ContentService>
        <ImgContentContainer>
          <BackgroundImg src={Rectangle1371Png} />
          <div>
            <img src={Img35Png} width={'100%'}/>
          </div>
        </ImgContentContainer>
        <InfoContainer>
          <TitleInfoContent textColor='warning80'>Mapa de Vendas</TitleInfoContent>
          <BlockInfoText>
          Visualize em nosso mapa onde seus produtos estão sendo comercializados.
          </BlockInfoText>
        </InfoContainer>
      </ContentService>
    </div>
  )
}

export default PerformaService
