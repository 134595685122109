import React from 'react'
import PrimaryText from '../PrimaryText'
import TitleText from '../TitleText'
import FormAboutUs from '../Forms/FormAboutUs'
import { AboutUsContainer } from './styles'

type Props = {
  children?: React.ReactNode
}

const AboutUsForm: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <AboutUsContainer flexDirection='column'>
      <TitleText color="primary-gradient">Contato</TitleText>
      <PrimaryText>Entre em contato conosco</PrimaryText>
      <iframe width="100%" height="750" src="https://conecta.somosdestra.com/forms/form1.php" title="Formulario" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </AboutUsContainer>
  )
}

export default AboutUsForm
