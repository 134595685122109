import styled from 'styled-components'
import { ContentContainer } from '../../../components/_common/Content/styles'

export const DiscoverOurServicesContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: fit-content;
  padding: 52px 0;
  margin: 100px 0;

  @media screen and (max-width: 800px) {
    ${ContentContainer} {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 500px) {
    /* padding: 5px; */
    display: flex;
    height: fit-content;
    flex-direction: row;
  }

  @media screen and (max-width: 380px) {
    display: flex;
    height: fit-content;
    flex-direction: row;
  }
`
