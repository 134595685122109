import styled from 'styled-components'
import { ButtonContainer } from '../../../Button/styles'

type Props = {
  theme: 'primary' | 'white'
 }

export const LoginBtnContainer = styled(ButtonContainer)<Props>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 131.23%;
  height: fit-content;

  @media screen and (max-width: 1200px) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    width: 70%;
  }

  @media screen and (max-width: 900px) {
    width: 90%;
  }
`
