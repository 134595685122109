import React from 'react'
import PrimaryText from '../../../components/PrimaryText'
import TitleText from '../../../components/TitleText'
import Carousel from './Carousel'
import {
  CustomersTestimonialsContainer

} from './styles'
import { testimonials } from './testimonials'

type Props = {
  children?: React.ReactNode
}

const CustomersTestimonials: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <CustomersTestimonialsContainer flexDirection='column'>
      <TitleText color="primary-gradient">Depoimentos</TitleText>
      <PrimaryText>O que nossos clientes têm a falar sobre as soluções da Destra?</PrimaryText>
      <Carousel data={testimonials}/>
    </CustomersTestimonialsContainer>
  )
}

export default CustomersTestimonials
