import React from 'react'
import { CaseItemContainer, ImageContainer } from './styles'

type Props = {
  children?: React.ReactNode
  srcImg: any
  onClick?: any
}

const CaseItem: React.FC<Props> = ({
  children,
  srcImg,
  onClick
}): JSX.Element => {
  return (
    <CaseItemContainer onClick={onClick}>
      <ImageContainer src={srcImg} />
      {children}
    </CaseItemContainer>
  )
}

export default CaseItem
