import React from 'react'
import ContactUs from '../../components/ContactUs'
import { MainContent } from '../../components/Footer/styles'
import TitleText from '../../components/TitleText'
import PrimaryText from '../../components/PrimaryText'

type Props = {
  children?: React.ReactNode
}

const ContactUsView: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <MainContent flexDirection='column'>
      <TitleText color="primary-gradient">Contato</TitleText>
      <PrimaryText>Fale conosco</PrimaryText>
      <iframe width="100%" height="750" src="https://conecta.somosdestra.com/forms/form4.php" title="Formulario" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </MainContent>
  )
}

export default ContactUsView
