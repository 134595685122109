import React, { useEffect } from 'react'

type Props = {
  url: string
}

const Redirect = ({ url }: Props) => {
  useEffect(() => {
    window.location.href = url
  }, [url])

  return null
}

export default Redirect
