import React from 'react'
import { MiddleContentContainer } from './styles'

type Props = {
  children?: React.ReactNode
}

const MiddleContent: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <MiddleContentContainer>
      {children}
    </MiddleContentContainer>
  )
}

export default MiddleContent
