import React, { useEffect, useState } from 'react'
import ContactUs from '../../components/ContactUs'
import Footer from '../../components/Footer'
import { MainContent } from '../../components/Footer/styles'
import Header from '../../components/Header'
import Content from '../../components/_common/Content'
import MainContainer from '../../components/_common/MainContainer'
import { SecundaryText } from '../Home/Partners/styles'
import CaseItem from './CaseItem'
import { CasesContainer, CaseText, CaseTitle, Text, Title } from './styles'
import Case1JPG from '../../assets/img/banner_vasco.png'
import Case2JPG from '../../assets/img/banner_gorros.png'
import Case3JPG from '../../assets/img/cases/banner_case_3.png'
import Case4JPG from '../../assets/img/cases/banner_case_4.png'
import { useNavigate } from 'react-router-dom'
import TitleText from '../../components/TitleText'
import PrimaryText from '../../components/PrimaryText'

type Props = {
  children?: React.ReactNode
}

const Cases: React.FC<Props> = ({
  children
}): JSX.Element => {
  const navigate = useNavigate()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/7d1897c6-e72c-4c2d-b15a-36916776e5e7-loader.js'
    script.async = true
    document.body.appendChild(script)
  }, [])

  return (
    <>
      <MainContainer>
        <Header />
        <MainContent flexDirection='column'>
          <Content flexDirection='column'>
            <Title>
              Nossos cases
              <div></div>
            </Title>

            <CasesContainer>
              <CaseItem onClick={() => navigate('/cases/case1')} srcImg={Case1JPG} >
                <CaseTitle color="warning">Case Vasco da Gama</CaseTitle>
                <CaseText>
                  Virada nos negócios: Vasco da Gama supera meta e arrecada R$ 6 milhões por ano com licenciamento
                </CaseText>
              </CaseItem>
              <CaseItem onClick={() => navigate('/cases/case2')} srcImg={Case2JPG} >
                <CaseTitle color="warning">Case G.F.A</CaseTitle>
                <CaseText>
                  Fabricante de gorros e cachecóis otimiza licenciamento com a Destra
                </CaseText>
              </CaseItem>
            </CasesContainer>
            <CasesContainer>
              <CaseItem onClick={() => navigate('/cases/case3')} srcImg={Case3JPG} >
                <CaseTitle color="warning">Case Chupa Chups na C&A</CaseTitle>
                <CaseText>
                  Em negócio intermediado pela Destra, Chupa Chups vira coleção de cosméticos na C&A
                </CaseText>
              </CaseItem>
              <CaseItem onClick={() => navigate('/cases/case4')} srcImg={Case4JPG} >
                <CaseTitle color="warning">Case Herbíssimo e Mentos</CaseTitle>
                <CaseText>
                  Herbíssimo e Mentos lançam linha exclusiva para banho com cheiro de balas
                </CaseText>
              </CaseItem>
            </CasesContainer>
          </Content>
        </MainContent>
        <MainContent flexDirection='column'>
          <TitleText color="primary-gradient">Contato</TitleText>
          <PrimaryText>Fale conosco</PrimaryText>
          <iframe width="100%" height="750" src="https://conecta.somosdestra.com/forms/form2.php" title="Formulario" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </MainContent>
      </MainContainer>
    </>
  )
}

export default Cases
