import React from 'react'
import { BaseOldUrl, BrandLicensing } from '../../../../shared/constants'
import { navigateTo } from '../../../../shared/helpers'
import { LogosItemContainer } from './styles'

type Props = {
  src: any
  children?: React.ReactNode
  hideInSmallViewPort?: boolean
  path?: string
}

const LogosItem: React.FC<Props> = ({
  children,
  hideInSmallViewPort = false,
  src,
  path
}): JSX.Element => {
  const adaptOldPlataformURL = () => `${BaseOldUrl}/${BrandLicensing}-${path}`

  return (
    <LogosItemContainer
      loading="lazy"
      hideInSmallViewPort={hideInSmallViewPort}
      src={src}
      path={path}
      onClick={path ? () => navigateTo(adaptOldPlataformURL()) : () => null}
    >
      {children}
    </LogosItemContainer>
  )
}

export default LogosItem
