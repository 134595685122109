import styled from 'styled-components'
import { H2Container } from '../_common/headers/H2/styles'

export const PrimaryTextContainer = styled(H2Container)<{
  colorText?: string,
  fontSize?: string,
  fontWeight?: number,
  lineHeight?: string,
}>`
  margin: 15px 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 700};
  font-size: ${props => props.fontSize ? props.fontSize : '52px'};
  line-height: ${props => props.lineHeight ? props.lineHeight : '68px'};
  color: ${props => props.colorText && props.theme[props.colorText]};

  @media screen and (max-width: 460px) {
    font-size: 12vw;
  }
`
