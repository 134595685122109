import styled from 'styled-components'
import { ContentContainer } from '../../components/_common/Content/styles'

export const BlockText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  max-width: 720px;

  text-align: left;
  margin: 10px 0px;
`

export const TextContainer = styled.div`
  margin-top: 20px;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const HeaderContent = styled.div`
  align-self: center;
  max-width: 700px;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 475px) {
    height: 90%;
  }
`

export const Logos = styled.img`

`

export const Div = styled.div`
  max-width: 767px
`

export const LinkServices = styled.div`
  width: 224px;
  height: 209px;
  margin: 40px auto;
  cursor: pointer;
`
export const ContentServices = styled(ContentContainer)`
  @media screen and (max-width: 850px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const ContentBackground = styled.div<{ bgColor: string }>`
  margin: 30px 0;
  background-color: ${props => props.theme[props.bgColor]};
  border-radius: 50px;
`
