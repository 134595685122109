import React from 'react'
import { LeftContentContainer } from './styles'

type Props = {
  children?: React.ReactNode
}

const LeftContent: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <LeftContentContainer>
      {children}
    </LeftContentContainer>
  )
}

export default LeftContent
