import React from 'react'

const renderWhatIsLicensing = () => (
  <div>
    Licenciamento de marcas e produtos é a “concessão por parte do detentor legal dos direitos de uso de determinada marca ou personagem para terceiros com o objetivo de exploração comercial”. Esta é a definição do nosso colega Marcus Macedo, no livro “A Arte do Licenciamento – Guia Completo para o licenciamento de marcas e personagens”.
    <br/>
    <a target={'_blank'} href='https://www.youtube.com/watch?v=jAZ33CcKT3Y&list=PLW0h_ugGCJBO2jRjeItQSbo1UOkwr1Vr4&index=1' rel="noreferrer">
      Quer saber mais?
    </a>
  </div>
)

const renderWhatIsMínima = () => (
  <div>
    De uma forma bem simples, Garantia Mínima ou Valor Mínimo Garantido, em licenciamento de marcas, é o valor solicitado pela marca como antecipação dos royalties para assinar um contrato de licenciamento com determinada empresa.
    <br/>
    <a target={'_blank'} href='https://somosdestra.com/saiba-o-que-e-garantia-minima-em-licenciamento-de-marcas' rel="noreferrer">
      Quer saber mais?
    </a>
  </div>
)

const renderWhatisRoyalties = () => (
  <div>
    São os valores financeiros que o fabricante (licenciado) paga ao detentor da marca (licenciador) pela concessão e utilização dos seus direitos. Eles são definidos com um percentual do valor de venda do produto do fabricante aos lojistas ou distribuidores.
    <br/>
    <a target={'_blank'} href='https://somosdestra.com/o-que-sao-royalties-no-licenciamento-de-marcas/' rel="noreferrer">
      Quer saber mais?
    </a>
  </div>
)

export const faqData = [
  {
    title: 'O que é licenciamento de marcas?',
    content: renderWhatIsLicensing()
  },
  {
    title: 'O que é garantia mínima?',
    content: renderWhatIsMínima()
  },
  {
    title: 'O que são Royalties?',
    content: renderWhatisRoyalties()
  },
  {
    title: 'Quanto custa para licenciar?',
    content: 'Na verdade não existe um valor ou custo fixo para realizar o licenciamento. O valor que deve ser pago para a marca são royalties, que são definidos na negociação entre a empresa licenciada e a marca. Além disso, a antecipação de royalties, que é o valor mínimo Garantido, ou Garantia Mínima, pelo contrato.'
  }
]
