import React from 'react'
import { MainContent } from '../../../components/Footer/styles'
import Header from '../../../components/Header'
import MainContainer from '../../../components/_common/MainContainer'
import HeaderImg from '../../../components/HeaderImg'
import { ButtonContainer, Case2Container, ContainerHeader, ContainerImage, HeaderContent } from './styles'
import Case2Header from '../../../assets/img/banner_gorros.v3.png'
import Footer from '../../../components/Footer'
import Content from '../../../components/_common/Content'
import Button from '../../../components/Button'
import ContactUs from '../../../components/ContactUs'
import { BlockText, TextContainer } from '../../AboutUs/styles'

type Props = {
  children?: React.ReactNode
}

const Case2: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <>
      <MainContainer >
        <HeaderImg src={Case2Header} height={'560px'} />
        <Header />
        <MainContent flexDirection='column'>
          <ContainerHeader>
            <Content justifyContent='start'>
              <HeaderContent>
                Fabricante de gorros e cachecóis otimiza licenciamento com a Destra
              </HeaderContent>
            </Content>
          </ContainerHeader>
          <Case2Container>
          <TextContainer>
            <BlockText>
                A G.F.A. conta com a Destra para a gestão de contratos, abrindo novos horizontes de negócios
            </BlockText>
            <BlockText>
                Atuante na confecção de gorros e cachecóis personalizados de diversos clubes profissionais e amadores, a G.F.A. é referência neste setor. Buscando focar em sua especialidade e ser mais assertiva no licenciamento de marcas dos clubes, passa a contar com a Destra, plataforma de LaaS (Licenciamento como Serviço).
            </BlockText>
            <BlockText>
                Além do constante suporte da equipe, a empresa de licenciamento apresenta mais ferramentas de gestão de contrato e controle de royalties, que mudaram toda a rotina de práticas da G.F.A. e, com isso, hoje, a empresa possui mais flexibilidade e assertividade para as suas ações. De acordo com Gustavo Weschenfelder, Fundador da G.F.A., o suporte da Destra para a empresa é fundamental. “Graças à Destra e todo o time, que não mede esforços para explicar todos os pontos importantes sempre que necessário, tornou-se possível termos mais acesso e conhecimento sobre o mundo do licenciamento”, comenta.
            </BlockText>
            <BlockText>
                Esse conhecimento, aliado às oportunidades de mercado que surgem em decorrência da parceria, permite que a G.F.A. almeje uma expansão constante. Além disso, a proximidade do time da Destra com os principais clubes e marcas presentes no Brasil traz segurança para os seus clientes no momento de assinar contratos.
            </BlockText>
          </TextContainer>
          <TextContainer>
            <BlockText>
                A parceria não só viabiliza novos horizontes para a G.F.A., mas também divide as demandas e a gestão burocrática. “Por meio dos contratos de licenciamento, que nos projetam cada dia mais dentro do mercado esportivo, podemos focar em nos aprimorar e melhorar internamente nossos produtos, atendimento e canais de venda, por exemplo”, explica Gustavo. Ele ainda completa pontuando que “sempre que penso em expandir, renovar maquinário, fazer investimentos na empresa, eu faço com a certeza de que não faltarão clientes, graças à Destra”.
            </BlockText>
            <BlockText>
                Para Bruno Koerich, CEO da Destra, o licenciamento é uma estratégia em ascensão, que domina uma grande fatia do mercado nacional e internacional. “É uma tendência em todo o mundo. Atendemos clientes que atuam tanto no Brasil quanto em outros países e notamos a importância do licenciamento em um âmbito global”, diz. “É um mercado originador de oportunidades, e a Destra possui a expertise e tecnologia necessárias para potencializar diversos negócios. Ficamos felizes com o andamento do projeto da G.F.A. e esperamos licenciar muitos produtos junto a eles”, conclui.
            </BlockText>
          </TextContainer>
          </Case2Container>
        </MainContent>
        <ContactUs />
      </MainContainer>
    </>
  )
}

export default Case2
