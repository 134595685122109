import styled from 'styled-components'

export const LogosItemContainer = styled.img<{hideInSmallViewPort?: boolean, path?: string}>`
  height: 56px;
  width: 56px;
  margin: 20px 20px;
  cursor: ${props => props.path && 'pointer'};

  @media screen and (max-width: 1200px) {
    ${props => props.hideInSmallViewPort && `
      display: none;
    `}
  }

  @media screen and (max-width: 450px ) {
    margin: 20px 10px;
  }
`
