import React from 'react'
import {
  CarouselSocialMediaContainer1100px,
  CarouselSocialMediaContainer600px,
  IconSvg,
  SocialMediaItem,
  SocialMediaItensContainer,
  SocialMediaLinkContainer
} from './styles'

import { Carousel } from 'react-responsive-carousel'
import SocialMediaBanner from '../SocialMediaBanner'
import { navigateTo } from '../../../../shared/helpers'
import { socialMediaItens } from './social-media-items'

type Props = {
  children?: React.ReactNode
}

const SocialMediaItens: React.FC<Props> = (): JSX.Element => {
  const renderSocialMediaItens = () => {
    return socialMediaItens.map((item, index) => (
        <SocialMediaItem
          onClick={() => navigateTo(item.href)}
          key={index}
        >
          <SocialMediaBanner banner={item.banner} />
          <SocialMediaLinkContainer>
            {item.label}
            <IconSvg src={item.srcIcon} loading="lazy"/>
          </SocialMediaLinkContainer>
        </SocialMediaItem>
    ))
  }

  return (
    <>
      <SocialMediaItensContainer>
        {
          renderSocialMediaItens()
        }
      </SocialMediaItensContainer>

      <CarouselSocialMediaContainer1100px>
        <Carousel
          centerMode
          infiniteLoop
          emulateTouch
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          centerSlidePercentage={55}
        >
          {
            renderSocialMediaItens()
          }
        </Carousel>
      </CarouselSocialMediaContainer1100px>

      <CarouselSocialMediaContainer600px>
        <Carousel
          centerMode
          infiniteLoop
          emulateTouch
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          centerSlidePercentage={100}
        >
          {
            renderSocialMediaItens()
          }
        </Carousel>
      </CarouselSocialMediaContainer600px>
    </>
  )
}

export default SocialMediaItens
