import React from 'react'
import { MainContainer as MainContainerStyled } from './style'

type Props = {
  children?: React.ReactNode
}

const MainContainer = ({ children }: Props): JSX.Element => {
  return (
    <MainContainerStyled>
      { children }
    </MainContainerStyled>
  )
}

export default MainContainer
