import React from 'react'

import {
  IconTop,
  ContentService,
  Text,
  TitleInfoContent,
  BlockInfoText
} from '../styles'
import TransformaIcon from '../../../assets/logos/service-logos/transforma-icon-otimizado.png'

import Group9189 from '../../../assets/img/service-type/Group9189-otimizado.png'
import Group9190 from '../../../assets/img/service-type/Group9190-otimizado.png'
import Group9191 from '../../../assets/img/service-type/Group9191-otimizado.png'

import { ContentItem, IconContainer, InfoContainer } from './styles'
import { navigateTo } from '../../../shared/helpers'
import PrimaryTextService from '../PrimaryTextService'

type Props = {
  children?: React.ReactNode
}

const TransformaService: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <div>
      <IconTop src={TransformaIcon}/>
      <PrimaryTextService>
        A <Text color="success90">capacitação do seu time de licenciamento</Text>, com conteúdos sobre gestão, varejo, produtos e muito mais
      </PrimaryTextService>
      <ContentService flexDirection='column'>

        {/* Content 1 */}
        <ContentItem>
          <InfoContainer>
            <TitleInfoContent textColor='success90'>Curso: Como ter grandes marcas nos seus produtos</TitleInfoContent>
            <BlockInfoText>
              Esse curso é para todas as empresas que desejam ter grandes marcas nos seus produtos. Trazemos uma abordagem específica sobre como desenvolver um programa completo de Licenciamento de Marcas para a Indústria, Fabricantes de Bens de Consumo, e Varejistas, que queiram ter grandes marcas nos seus produtos através do Licenciamento.
              <br />
              <br />
              <a
                target={'_blank'}
                href='https://material.somosdestra.com/licenciamento-de-marca-do-conceito-a-comercializacao-2022' rel="noreferrer">
                  Saiba mais
              </a>
            </BlockInfoText>
          </InfoContainer>
          <IconContainer
            onClick={() =>
              navigateTo('https://material.somosdestra.com/licenciamento-de-marca-do-conceito-a-comercializacao-2022')
            }
            src={Group9189}
          />
        </ContentItem>

        {/* Content 2 */}
        <ContentItem>
          <InfoContainer>
            <TitleInfoContent textColor='success90'>Canal no Youtube</TitleInfoContent>
            <BlockInfoText>
            Conheça nossos vídeos sobre licenciamento de marcas e aprofunde o seu conhecimento. Abordamos conceitos mais básicos sobre introdução ao licenciamento de marcas e mais complexos, como calcular garantia mínima e royalties.
            <br />
            <br />
            <a
              target={'_blank'}
              href='https://www.youtube.com/channel/UCody8nJOfJZaNXYGy1mPUGQ' rel="noreferrer">
                Saiba mais
            </a>
            </BlockInfoText>
          </InfoContainer>
          <IconContainer
            onClick={() =>
              navigateTo('https://www.youtube.com/channel/UCody8nJOfJZaNXYGy1mPUGQ')
            }
            src={Group9190}
          />
        </ContentItem>

        {/* Content 3 */}
        <ContentItem>
          <InfoContainer>
            <TitleInfoContent textColor='success90'>Blog</TitleInfoContent>
            <BlockInfoText>
            Conheça nosso blog e saiba mais sobre licenciamento de marcas. Lá você encontrará um extenso conteúdo sobre licenciamento de uma forma bastante didática que poderá ajudar você a aprofundar seus conhecimentos.
            <br />
            <br />
            <a
              target={'_blank'}
              href='https://somosdestra.com/blog/' rel="noreferrer">
                Saiba mais
            </a>
            </BlockInfoText>
          </InfoContainer>
          <IconContainer
            onClick={() => navigateTo('https://somosdestra.com/blog/')}
            src={Group9191}
          />
        </ContentItem>

        {/* Content 4 */}
        <ContentItem>
          <InfoContainer>
            <TitleInfoContent textColor='success90'>E-book: Como ter grandes marcas nos seus produtos</TitleInfoContent>
            <BlockInfoText>
            Escrito por Rafael Pedreira, o e-book traz desde os conceitos mais básicos de licenciamento, passando por exemplos de como negociar com marcas e cases de licenciamento. Leitura indispensável para quem deseja conhecer tudo sobre o mercado de licenciamento. Acesse a página e realize o download.
            </BlockInfoText>
          </InfoContainer>
          <IconContainer src={Group9190}/>
        </ContentItem>
      </ContentService>
    </div>
  )
}

export default TransformaService
