import styled from 'styled-components'

type Props = {
  onClick?: () => void;
}

export const ToggleBtnContainer = styled.div<Props>`
  background-color: ${props => props.theme.white};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 48px;
  height: 48px;
  border-radius: 24px;
`

export const Line = styled.div`
  border-bottom: 2px solid ${props => props.theme.black};
  border-radius: 1px;
  width: 22px;
  height: 2px;
  margin: 2px;
`

export const LineX1 = styled(Line)`
  position: absolute;
  transform: rotate(45deg);
  width: 50%;
  margin: 0;
  padding: 0;
  height: 0;
`

export const LineX2 = styled(Line)`
  height: 0;
  margin: 0;
  padding: 0;
  transform: rotate(135deg);
  width: 50%;
`
