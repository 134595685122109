import styled from 'styled-components'
import { LinkItemContainer } from '../../_common/LinkItem/styles'

export const CollapseContentContainer = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1920px) {
    display: flex;
    width: fit-content;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1280px) {
    display: flex;
    width: fit-content;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1200px) {
    display: none;
    float: none;
  }
`

export const ToggleButtonContainer = styled.div`
  display: none;
  z-index: 999;

  @media screen and (max-width: 1200px) {
    display: flex;
    position: fixed;
    right: 16px;
    overflow: auto;
  }
`
export const CollapsedMenu = styled.div`
  display: none;
  transition: all .2s ease-in-out;
  flex-direction: column;
  position: fixed;
  background-color: ${props => props.theme.white};

  @media screen and (max-width: 1200px) {
    overflow-y: auto;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    padding: 10px;
    display: flex;
  }
`

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const BtnContainer = styled.div`
  width: 100%;
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
`
export const BrandIndustriSelect = styled.div<{colorText?: string}>`
  position: absolute;
  height: 0px;

  background-color: ${props => props.colorText === 'white' ? props.theme.primary : props.theme.gray10};

  @media screen and (max-width: 900px) {
    background-color: ${props => props.theme.gray10};
  }

  border-radius: 0px 0px 10px 10px;

  ${LinkItemContainer} {
    opacity: 0;
    margin: 7px auto;
  }
`

export const LinkItemOurServices = styled.div<{colorText?: string}>`
  position: relative;

  &:hover {
    background-color: ${props => props.colorText === 'white' ? props.theme.primary : props.theme.gray10};

    @media screen and (max-width: 900px) {
      background-color: ${props => props.theme.gray10};
    }

    border-radius: 10px 10px 0px 0px;
    padding: 2px 0;

    ${BrandIndustriSelect} {

      ${LinkItemContainer} {
        opacity: 1;
        color: ${props => props.colorText === 'white' ? props.theme.black : props.theme.white};
        transition: all 0.2s ease-in-out;

      }

      display: flex;
      flex-direction: column;
      width: 100%;
      height: max-content;

      @media screen and (max-width: 900px) {
        position: relative;
      }
    }
  }
`
