import React, { useEffect, useState } from 'react'
import BrandServicesHeaderJpg from '../../../assets/img/BrandServicesHeader.jpg'
import { MainContent } from '../../../components/Footer/styles'
import HeaderImg from '../../../components/HeaderImg'
import PrimaryText from '../../../components/PrimaryText'
import TitleText from '../../../components/TitleText'
import {
  ContainerSelect,
  ContainerTitle,
  HeaderContentServices,
  SublimeTitle
} from '../styles'
import SelectServiceType from '../SelectServiceType'
import ContactUs from '../../../components/ContactUs'
import Partners from '../Partners'
import PerformaService from './PerformaService'
import ConectaService from './ConectaService'
import TransformaService from '../TransformaService'
import { ServiceTypeEnum } from '../../../shared/enums'
import { useLocation } from 'react-router-dom'

type Props = {
  children?: React.ReactNode
}

const IndustrySolutions: React.FC<Props> = ({
  children
}): JSX.Element => {
  const [serviceType, setServiceType] = useState<ServiceTypeEnum>(ServiceTypeEnum.PERFORMA)
  const { state } = useLocation()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    setServiceType(state as ServiceTypeEnum || ServiceTypeEnum.PERFORMA)
  }, [])

  const renderIndustyServiceContent = () => {
    switch (serviceType) {
      case ServiceTypeEnum.PERFORMA:
        return <PerformaService />
      case ServiceTypeEnum.CONECTA:
        return <ConectaService />
      case ServiceTypeEnum.TRANSFORMA:
        return <TransformaService />
      default:
        break
    }
  }

  return (
    <>
      <HeaderImg src={BrandServicesHeaderJpg} height={'300px'}/>
      <MainContent flexDirection='column' >
        <HeaderContentServices>
        <ContainerTitle>
            <PrimaryText colorText='white'>
              Soluções para Empresas
            </PrimaryText>
            <SublimeTitle />
          </ContainerTitle>
          <ContainerSelect>
            <SelectServiceType
              setActiveService={setServiceType}
              activeService={serviceType}
            />
          </ContainerSelect>
        </HeaderContentServices>
        {
          renderIndustyServiceContent()
        }
        <Partners partner='industries' />
      </MainContent>
      <MainContent flexDirection='column'>
        <TitleText color="primary-gradient">Contato</TitleText>
        <PrimaryText>Fale conosco</PrimaryText>
        <iframe width="100%" height="750" src="https://conecta.somosdestra.com/forms/form4.php" title="Formulario" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </MainContent>
    </>
  )
}
export default IndustrySolutions
