import React from 'react'
import { Container, DivisorContainer } from './styles'

type Props = {
  children?: React.ReactNode
  width?: string
  color?: string
}

const Divisor: React.FC<Props> = ({
  children,
  width,
  color
}): JSX.Element => {
  return (
    <Container>
      <DivisorContainer
        width={width}
        color={color}
      >
        {children}
      </DivisorContainer>
    </Container>
  )
}

export default Divisor
