import React from 'react'
import { MainContent } from '../../../components/Footer/styles'
import Header from '../../../components/Header'
import MainContainer from '../../../components/_common/MainContainer'
import HeaderImg from '../../../components/HeaderImg'
import { ButtonContainer, Case1Container, ContainerHeader, ContainerImage, ContainerImage2, HeaderContent } from './styles'
import CaseDetalhes1 from '../../../assets/img/cases/case3_detalhe.jpg'
import Case1Header from '../../../assets/img/cases/case3_header.png'
import Footer from '../../../components/Footer'
import Content from '../../../components/_common/Content'
import Button from '../../../components/Button'
import ContactUs from '../../../components/ContactUs'
import { BlockText, TextContainer } from '../../AboutUs/styles'

type Props = {
  children?: React.ReactNode
}

const Case3: React.FC<Props> = ({
  children
}): JSX.Element => {
  return (
    <>
      <MainContainer >
        <HeaderImg src={Case1Header} height={'560px'} />
        <Header />
        <MainContent flexDirection='column'>
          <ContainerHeader>
            <Content justifyContent='start'>
              <HeaderContent>
                Em negócio intermediado pela Destra, Chupa Chups vira coleção de cosméticos na C&A
              </HeaderContent>
            </Content>
          </ContainerHeader>
          <Case1Container>
          <TextContainer>
            <BlockText>
                A divisão de beleza da C&A Brasil, chamada Bel&za C&A, recentemente lançou uma linha de produtos de banho e corpo em colaboração com a popular marca de doces, Chupa Chups, da Perfetti Van Melle.
            </BlockText>
            <BlockText>
                A linha tem como objetivo trazer de volta o aroma clássico do pirulito de morango da Chupa Chups e apresenta uma embalagem nostálgica dos anos 1950 e 1960, quando a marca de doces foi lançada. Composta por cinco itens, incluindo desodorante corporal, body splash, sabonete, loção hidratante e creme para as mãos, a coleção combina diversão com cuidados funcionais para o corpo. Além disso, há uma camiseta disponível com o famoso logo do pirulito.
            </BlockText>
          </TextContainer>
          <ContainerImage2 >
            <img src={CaseDetalhes1} />
          </ContainerImage2>
          <TextContainer>
            <BlockText>
                Os produtos estarão disponíveis nas lojas físicas da C&A, no aplicativo da varejista e na plataforma de e-commerce.
            </BlockText>
            <BlockText>
                A colaboração foi conduzida pela Destra.
            </BlockText>
            <BlockText>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/TBmFo-4CzgA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </BlockText>
          </TextContainer>
          </Case1Container>
        </MainContent>
        <ContactUs />
      </MainContainer>
    </>
  )
}

export default Case3
