import styled from 'styled-components'

export const HeaderImgContainer = styled.div`
  display: flex;
  position: relative;
  height: fit-content;
  top: 0;
  right: 0;
  left: 0;
`

export const ImageBackgroud = styled.img<{ height?: string }>`
  width: 100%;
  min-height: ${props => props.height ? props.height : '640px'};
  position: absolute;
  z-index: -1;

  /* @media screen and (max-width: 700px) {
    height-min: fit-content;
  } */
`
