import { BannerTypeEnum } from '../../../../shared/enums'
import { SocialMediaItemConfig } from '../../../../shared/types'

import InstagramSVG from '../../../../assets/img/social-media/instagram_circle_color-otimizado.png'
import TwiterSVG from '../../../../assets/img/social-media/twitter_circle_color-otimizado.png'
import LinkedinSVG from '../../../../assets/img/social-media/linkedin_circle_color-otimizado.png'
import YoutubeSVG from '../../../../assets/img/social-media/youtube_circle_color-otimizado.png'
import InstagramBannerJPG from '../../../../assets/img/instagram-banner.jpg'
import YoutubeBannerJPG from '../../../../assets/img/thumb-video-destra-14-otimizado.png'

export const socialMediaItens: SocialMediaItemConfig[] = [
  {
    href: 'https://www.instagram.com/somosdestra/',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis sit non elit.',
    srcIcon: InstagramSVG,
    label: '@somosdestra',
    banner: {
      type: BannerTypeEnum.IMAGE,
      src: InstagramBannerJPG
    }
  },
  {
    href: 'https://twitter.com/somosdestra',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis sit non elit.',
    srcIcon: TwiterSVG,
    label: '@somosdestra',
    banner: {
      type: BannerTypeEnum.IMAGE,
      src: 'https://pbs.twimg.com/media/FXflrrFX0Agvo-G?format=jpg&name=small'
    }

  },
  {
    href: 'https://www.linkedin.com/company/somosdestra/',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis sit non elit.',
    srcIcon: LinkedinSVG,
    label: '@somosdestra',
    banner: {
      type: BannerTypeEnum.IMAGE,
      src: 'https://media-exp1.licdn.com/dms/image/C4D22AQGyVSoOyX-M4w/feedshare-shrink_800/0/1664321595713?e=1667433600&v=beta&t=mCXvpQ6B6SJ1P_H9VZjcMo3XdJgivgVkLryrdPNJb0Y'
    }

  },
  {
    href: 'https://www.youtube.com/channel/UCody8nJOfJZaNXYGy1mPUGQ',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis sit non elit.',
    srcIcon: YoutubeSVG,
    label: '@somosdestra',
    banner: {
      type: BannerTypeEnum.IMAGE,
      src: YoutubeBannerJPG
    }

  }
]
